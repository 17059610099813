import brandConstants from '@/util/brandConstants';
import {
  UpdateOralBUser,
  DeleteOralBUser,
  GetBrushingSessionsQuery,
  CreateBrushingSessionMutation,
  DeleteOralBSessionMutation,
  GetConsumablesQuery,
  CreateConsumableMutation,
  UpdateConsumableMutation,
  GetOralBDeviceQuery,
  GetOralBDevicesQuery,
  CreateOralBDeviceMutation,
  UpdateOralBDeviceMutation,
  DeleteOralBDeviceMutation,
  GetOralBDeviceStatusesQuery,
  GetOralBVoiceAssistQuery,
  GetOralBBrushingModeTypesQuery,
  GetConsumableTypesQuery,
  GetConsumableStateQuery
} from '@/gql/oralBQueries';

import {
  UpdateOpteUser,
  DeleteOpteUser,
  GetOpteDeviceTypesQuery,
  GetOpteDevicesQuery,
  GetOpteDeviceQuery,
  ClaimOpteDeviceMutation,
  UpdateOpteDeviceMutation,
  DeleteOpteDeviceMutation,
  GetOpteSessionsQuery,
  CreateOpteSessionMutation,
  DeleteOpteSessionMutation
} from '@/gql/opteQueries';

import {
  GetGilletteDeviceQuery,
  GetGilletteDevicesQuery,
  UpdateGilletteDeviceMutation,
  GetGilletteSessionsQuery,
  UnclaimGilletteDeviceMutation,
  DeleteGilletteDeviceMutation,
  DeleteGilletteUser,
  UpdateGilletteUser,
  CreateGilletteSessionMutation,
  DeleteGilletteSessionMutation,
  ClaimGilletteDeviceMutation,
  CreateGilletteDeviceMutation,
  GetGilletteDeviceTypesQuery,
  GetGilletteDeviceStatusesQuery,
  GetGilletteDeviceFirmwaremodeQuery,
  GetGilletteDeviceHandleDockedQuery,
  GetGilletteDeviceHandleModeQuery,
  GetGilletteCartridgeChangeQuery,
  GetGilletteDiagnosticResultQuery
} from '@/gql/gilletteQueries';

import {
  GetLabsDeviceQuery,
  GetLabsDevicesQuery,
  UpdateLabsDeviceMutation,
  GetLabsSessionsQuery,
  GetLabsDeviceStatusesQuery,
  DeleteLabsDeviceMutation,
  DeleteLabsUser,
  UpdateLabsUser,
  CreateLabsOTA,
  GetAllLabsJobs,
  CreateLabsSessionMutation,
  DeleteLabsSessionMutation,
  CreateLabsDeviceMutation
} from '@/gql/labsQueries';

import {
  GetBraunDeviceQuery,
  GetBraunDevicesQuery,
  GetBraunSessionsQuery,
  GetBraunSessionStatusEnums,
  GetBraunSessionTypeEnums,
  GetBraunLogLevelEnums,
  GetBraunClientEnums,
  GetBraunDeviceStatusEnums,
  CreateBraunDevice,
  CreateBraunSession,
  DeleteBraunDevice,
  DeleteBraunSession,
  DeleteBraunUser,
  UpdateBraunDevice,
  UpdateBraunUser
} from '@/gql/braunQueries';

import {
  GetAllDeviceTypesQuery,
  GetLogsQuery,
  GetLogQuery,
  GetSchemaQuery,
  GetAllJobs,
  CancelJobMutation,
  CreateOTA,
  GetTaskTypesQuery,
  GetIntegrationsQuery,
  GetValidationErrorsQuery,
  AuthorizeIntegrationMutation,
  RevokeIntegrationAccessMutation
} from '@/gql/agnosticQueries';

import {
  oralbDeviceColumns,
  oralbSingleDeviceColumns,
  oralbSessionsColumns,
  opteDeviceColumns,
  opteSessionsColumns,
  gilletteDeviceColumns,
  gilletteSessionsColumns,
  labsDeviceColumns,
  labsSessionsColumns,
  braunDeviceColumns,
  braunSessionsColumns,
  consumablesColumns,
  logsColumns,
  integrationsColumns,
  validationsColumns
} from '@/util/columns';

const labsConfig = {
  user: {
    updateMutation: UpdateLabsUser,
    deleteMutation: DeleteLabsUser
  },
  devices: {
    query: GetLabsDevicesQuery,
    queryAllDeviceTypes: GetAllDeviceTypesQuery,
    addMutation: CreateLabsDeviceMutation,
    updateMutation: UpdateLabsDeviceMutation,
    deleteMutation: DeleteLabsDeviceMutation,
    querySingleDevice: GetLabsDeviceQuery,
    queryStatuses: GetLabsDeviceStatusesQuery,
    columns: labsDeviceColumns
  },
  device: {
    columns: labsDeviceColumns
  },
  jobs: {
    query: GetAllLabsJobs,
    addMutation: CreateLabsOTA,
    cancelMutation: CancelJobMutation,
    queryTaskTypes: GetTaskTypesQuery
  },
  sessions: {
    query: GetLabsSessionsQuery,
    columns: labsSessionsColumns,
    addMutation: CreateLabsSessionMutation,
    deleteSession: DeleteLabsSessionMutation
  }
};

const oralBConfig = {
  user: {
    updateMutation: UpdateOralBUser,
    deleteMutation: DeleteOralBUser
  },
  devices: {
    query: GetOralBDevicesQuery,
    querySingleDevice: GetOralBDeviceQuery,
    addMutation: CreateOralBDeviceMutation,
    updateMutation: UpdateOralBDeviceMutation,
    deleteMutation: DeleteOralBDeviceMutation,
    queryAllDeviceTypes: GetAllDeviceTypesQuery,
    queryStatuses: GetOralBDeviceStatusesQuery,
    queryVoiceConfig: GetOralBVoiceAssistQuery,
    queryBrushingModeTypes: GetOralBBrushingModeTypesQuery,
    columns: oralbDeviceColumns
  },
  device: {
    columns: oralbSingleDeviceColumns
  },
  sessions: {
    deleteSession: DeleteOralBSessionMutation,
    query: GetBrushingSessionsQuery,
    addMutation: CreateBrushingSessionMutation,
    columns: oralbSessionsColumns
  },
  consumables: {
    query: GetConsumablesQuery,
    columns: consumablesColumns,
    queryTypes: GetConsumableTypesQuery,
    queryStates: GetConsumableStateQuery,
    addMutation: CreateConsumableMutation,
    updateMutation: UpdateConsumableMutation
  }
};

const gilletteConfig = {
  user: {
    updateMutation: UpdateGilletteUser,
    deleteMutation: DeleteGilletteUser
  },
  devices: {
    query: GetGilletteDevicesQuery,
    addMutation: CreateGilletteDeviceMutation,
    claimMutation: ClaimGilletteDeviceMutation,
    updateMutation: UpdateGilletteDeviceMutation,
    unclaimMutation: UnclaimGilletteDeviceMutation,
    deleteMutation: DeleteGilletteDeviceMutation,
    querySingleDevice: GetGilletteDeviceQuery,
    columns: gilletteDeviceColumns,
    queryTypes: GetGilletteDeviceTypesQuery,
    queryStatuses: GetGilletteDeviceStatusesQuery,
    queryFirmwaremode: GetGilletteDeviceFirmwaremodeQuery,
    queryHandleDocked: GetGilletteDeviceHandleDockedQuery,
    queryHandleMode: GetGilletteDeviceHandleModeQuery
  },
  device: {
    columns: gilletteDeviceColumns
  },
  sessions: {
    query: GetGilletteSessionsQuery,
    columns: gilletteSessionsColumns,
    addMutation: CreateGilletteSessionMutation,
    deleteSession: DeleteGilletteSessionMutation,
    queryCartridgeChange: GetGilletteCartridgeChangeQuery,
    queryDiagnosticResult: GetGilletteDiagnosticResultQuery
  }
};

const braunConfig = {
  user: {
    updateMutation: UpdateBraunUser,
    deleteMutation: DeleteBraunUser
  },
  devices: {
    query: GetBraunDevicesQuery,
    queryAllDeviceTypes: GetAllDeviceTypesQuery,
    queryStatuses: GetBraunDeviceStatusEnums,
    queryLogLevel: GetBraunLogLevelEnums,
    addMutation: CreateBraunDevice,
    updateMutation: UpdateBraunDevice,
    deleteMutation: DeleteBraunDevice,
    querySingleDevice: GetBraunDeviceQuery,
    columns: braunDeviceColumns
  },
  device: {
    columns: braunDeviceColumns
  },
  sessions: {
    query: GetBraunSessionsQuery,
    querySessionStatus: GetBraunSessionStatusEnums,
    querySessionType: GetBraunSessionTypeEnums,
    queryClient: GetBraunClientEnums,
    columns: braunSessionsColumns,
    addMutation: CreateBraunSession,
    deleteSession: DeleteBraunSession
  }
};

export default {
  [brandConstants.opte]: {
    user: {
      updateMutation: UpdateOpteUser,
      deleteMutation: DeleteOpteUser
    },
    devices: {
      query: GetOpteDevicesQuery,
      querySingleDevice: GetOpteDeviceQuery,
      addMutation: ClaimOpteDeviceMutation,
      updateMutation: UpdateOpteDeviceMutation,
      deleteMutation: DeleteOpteDeviceMutation,
      queryTypes: GetOpteDeviceTypesQuery,
      columns: opteDeviceColumns
    },
    device: {
      columns: opteDeviceColumns
    },
    sessions: {
      query: GetOpteSessionsQuery,
      addMutation: CreateOpteSessionMutation,
      deleteSession: DeleteOpteSessionMutation,
      columns: opteSessionsColumns
    }
  },
  [brandConstants.oralb]: oralBConfig,
  [brandConstants.oralbEU]: oralBConfig,
  [brandConstants.oralbAP]: oralBConfig,
  [brandConstants.labs]: labsConfig,
  [brandConstants.labsEU]: labsConfig,
  [brandConstants.labsAP]: labsConfig,
  [brandConstants.gillette]: gilletteConfig,
  [brandConstants.gilletteEU]: gilletteConfig,
  [brandConstants.braun]: braunConfig,
  [brandConstants.braunAP]: braunConfig,
  [brandConstants.agnostic]: {
    logs: {
      query: GetLogsQuery,
      dataPath: 'logs.items',
      dataType: 'logs',
      columns: logsColumns
    },
    log: {
      query: GetLogQuery,
      dataPath: 'logs.items',
      dataType: 'logs',
      columns: logsColumns
    },
    jobs: {
      query: GetAllJobs,
      addMutation: CreateOTA,
      cancelMutation: CancelJobMutation,
      queryTaskTypes: GetTaskTypesQuery
    },
    schema: {
      query: GetSchemaQuery,
      dataPath: '__schema',
      dataType: 'schema'
    },
    integrations: {
      query: GetIntegrationsQuery,
      addMutation: AuthorizeIntegrationMutation,
      revokeMutation: RevokeIntegrationAccessMutation,
      columns: integrationsColumns
    },
    validations: {
      query: GetValidationErrorsQuery,
      columns: validationsColumns
    }
  }
};
