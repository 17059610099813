export const updateUserData = {
  data: {
    updateUser: {
      familyName: 'test',
      givenName: 'user'
    }
  }
};

export const deleteUserData = {
  data: {
    deleteUser: 'aac488bc-1ede-46f5-b3d5-e748a09f9df6 Deleted Successfully'
  }
};
