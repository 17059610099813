export default {
  oralb: 'Oral-B NA',
  oralbEU: 'Oral-B EU',
  oralbAP: 'Oral-B AP',
  opte: 'Opte NA',
  gillette: 'Gillette NA',
  gilletteEU: 'Gillette EU',
  labs: 'Labs NA',
  labsEU: 'Labs EU',
  labsAP: 'Labs AP',
  braun: 'Braun NA',
  braunEU: 'Braun EU',
  braunAP: 'Braun AP',
  agnostic: 'agnostic'
};
