export default {
  data: {
    __schema: {
      queryType: {
        name: 'Query'
      },
      mutationType: {
        name: 'Mutation'
      },
      subscriptionType: {
        name: 'Subscription'
      },
      types: [
        {
          kind: 'OBJECT',
          name: 'Query',
          description: '  graphql queries: anything that retrieves data scoped to the logged in user',
          fields: [
            {
              name: 'devices',
              description: '  retrieves all user devices, if deviceId is passed only returns one',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'Device',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'device',
              description: '  retrieves a single devices current state based on deviceId',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Device',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'logs',
              description: '  retrieves all user device logs filtered by inputs',
              args: [
                {
                  name: 'filter',
                  description: null,
                  type: {
                    kind: 'INPUT_OBJECT',
                    name: 'TableLogFilterInput',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'limit',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Int',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'nextToken',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'LogConnection',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'pendingJobs',
              description: '  retrieves all pending jobs for the provided device',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Jobs',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'allJobs',
              description: '  retrieves all consumer jobs for the provided device',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Jobs',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'replenishmentToken',
              description: '  retrieves an AWS access token for replenishment',
              args: [
                {
                  name: 'replenishmentId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessions',
              description: '  retrieves all user brushing sessions filtered by inputs',
              args: [
                {
                  name: 'start',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'AWSDateTime',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'end',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'AWSDateTime',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'filter',
                  description: null,
                  type: {
                    kind: 'INPUT_OBJECT',
                    name: 'TableSessionFilterInput',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'limit',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Int',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'orderBySessionStartTime',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'Sort',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'nextToken',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'SessionConnection',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'user',
              description: '  retrieves User details for logged in user',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'User',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'consumables',
              description: '  retrieves all user consumables',
              args: [
                {
                  name: 'filter',
                  description: null,
                  type: {
                    kind: 'INPUT_OBJECT',
                    name: 'TableConsumableFilterInput',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'limit',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Int',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'nextToken',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'ConsumableConnection',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Device',
          description: '  device state data model for oralcare',
          fields: [
            {
              name: 'consumables',
              description: '  PLATFORM MANAGED\n what consumables the device has',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'Consumable',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createdBy',
              description: '  identifies which interface triggered the creation of the state record',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'CreatedBy',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deviceId',
              description: '  device 128bit UUID v4 algorithm',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deviceType',
              description: '  hardware model as defined by the internal engineering name/number',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'DeviceType',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'receivedTime',
              description: '  time message was received by platform',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'stateId',
              description: '  device state ID',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'thingName',
              description: '  thiing name',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'traceId',
              description: '  platform trace ID',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'consumerId',
              description: '  PLATFORM REQUIRED\n unique Id generated by Cognito',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'status',
              description: '  current state of the device',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'DeviceStatus',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'brushHeadDate',
              description: '  DEVICE ATTRIBUTES\n ISO-8601 Datetime that the brushhead was installed',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'firmwareVersion',
              description: '  firmware version of the device',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'logLevel',
              description: '  log verbosity',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'LogLevel',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'macAddress',
              description: '  macAddress of device Bluetooth chip',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'name',
              description: '  user given device name',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'operatingSystem',
              description: '  operating system of client connecting to labs',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'OperatingSystem',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'operatingSystemVersion',
              description: '  operating system version of client connecting to labs',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'parentDeviceId',
              description: '  gateway device 128bit UUID v4 algorithm',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'protocolVersion',
              description: '  protocol version from bluetooth specification as defined by P&G(decimal converted version of hex code)',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'remainingBrushHeadDays',
              description: '  calculated value on the number of days left on a brush head',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ringColor',
              description: '  hex code (#FFFFFF)',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'rssi',
              description: '  received signal strength indicator (wifi) - rfrssi Values: 65 and above (good/green),80 to 65 (okay, yellow), 80 and below (bad, red) - only updated when device moves bands (ex. good to bad, bad to okay)',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ssid',
              description: '  wifi network name',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'voiceAssistantConfigured',
              description: '  voice assistant has been setup by end user',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'VoiceAssistantStatus',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Consumable',
          description: '  consumable data model for oralcare',
          fields: [
            {
              name: 'consumableId',
              description: '  ID of the consumable',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deviceId',
              description: '  The ID of the device this consumable is attached to',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'startDate',
              description: '  time the consumable was created',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSDateTime',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'consumableType',
              description: '  type of consumable',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'ConsumableType',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'totalSessionCount',
              description: '  session count totalizer - updated in the session status stream',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'totalSessionDurationSeconds',
              description: '  session duration totalizer - updated in the session status stream',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'state',
              description: '  current state of the consumable, when a new consumable is created our mutation will automatically set this to IN_USE and set previous consumable to USED.',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'ConsumableState',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'ID',
          description: 'Built-in ID',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'AWSDateTime',
          description: 'The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'ConsumableType',
          description: '  we will assume unknown unless specified',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'CROSS_ACTION',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FLOSS_ACTION',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OTHER',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'PRECISION',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'PRO_GUM',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SENSITIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'THREE_D_WHITE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'Int',
          description: 'Built-in Int',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'ConsumableState',
          description: '  the state of the consumable',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'IN_USE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'USED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'CreatedBy',
          description: '  enum to represent interface device state created by',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'CLOUD_PROCESSING',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GQL',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'MQTT',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'DeviceType',
          description: '  enum to represent the different device types available to oralcare',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'BETAMAX',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'BOOMBOX_ALEXA',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'BOOMBOX_GOOGLE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_1_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_2A_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_2B_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_3_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_3_MODE_WHITENING',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D21_4_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D36_5_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D36_6_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_1_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_2A_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_2B_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_3A_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_3B_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_4_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D601_5_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D700_4_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D700_5_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D700_6_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D701_5_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D701_6_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D706_5_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'D706_6_MODE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M6',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M6_BIGTI',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M7',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M7_BIGTI',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M8',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M8_BIGTI',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M9',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'M9_BIGTI',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SEAGATE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'DeviceStatus',
          description: '  enum to represent the device state options.',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'FACTORY',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'PROVISIONED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'String',
          description: 'Built-in String',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'LogLevel',
          description: ' log verbosity',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DEBUG',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ERROR',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INFO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'WARNING',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'OperatingSystem',
          description: '  operating system client connecting to labs',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ANDROID',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'AOSP',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'CHROME',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FIREFOX',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FREERTOS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GREENGRASS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'IE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'IOS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'LINUX',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OTHER',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SAFARI',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'WECHAT',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'Float',
          description: 'Built-in Float',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'VoiceAssistantStatus',
          description: '  enum for voice assistant status',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DISABLED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ENABLED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'LogConnection',
          description: '  pageable object for device logs',
          fields: [
            {
              name: 'items',
              description: '  collection of device logs',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'Log',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'nextToken',
              description: '  collection Paging token',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Log',
          description: '  device log data model for oralcare',
          fields: [
            {
              name: 'consumerId',
              description: '  cognito user GUID',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'message',
              description: '  json object with any schema desired',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSJSON',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'receivedTime',
              description: '  epoch timestamp set by platform',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSDateTime',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deviceId',
              description: '  device ID of device in log',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'AWSJSON',
          description: "The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like \"**{\\\\\"upvotes\\\\\": 10}**\", lists like \"**[1,2,3]**\", and scalar values like \"**\\\\\"AWSJSON example string\\\\\"**\", \"**1**\", and \"**true**\" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like \"**{a: 1}**\", \"**{'a': 1}**\" and \"**Unquoted string**\" will throw GraphQL validation errors.",
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableLogFilterInput',
          description: '  log data model specific filter object',
          fields: null,
          inputFields: [
            {
              name: 'receivedTime',
              description: '  filter by log received time',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableStringFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'deviceId',
              description: '  filter logs by device ID',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIDFilterInput',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableStringFilterInput',
          description: '  generic dynamo filter input for string attributes',
          fields: null,
          inputFields: [
            {
              name: 'beginsWith',
              description: '  string value starts with value',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'between',
              description: '  string value is between X and Y',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'contains',
              description: '  string contains value',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'eq',
              description: '  equal',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ge',
              description: '  greater or equal >=',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gt',
              description: '  greater than >',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'le',
              description: '  less or equal <=',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'lt',
              description: '  less than <',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ne',
              description: '  not equal',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'notContains',
              description: "  string doesn't contain value",
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableIDFilterInput',
          description: '  generic dynamo filter input for ID attributes (serializes to string)',
          fields: null,
          inputFields: [
            {
              name: 'beginsWith',
              description: '  string value starts with value',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'between',
              description: '  between the range of multiple values',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'contains',
              description: '  contains part of value',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'eq',
              description: '  equal',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ge',
              description: '  greater or equal >=',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gt',
              description: '  greater than >',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'le',
              description: '  less or equal <=',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'lt',
              description: '  less than <',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ne',
              description: '  not equal',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'notContains',
              description: "  doesn't contain part of value",
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Jobs',
          description: '  The shape of a list of job summaries for a device',
          fields: [
            {
              name: 'executionSummaries',
              description: '  A list of JobExecutionSummary objects with status IN_PROGRESS.',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'JobExecutionSummaries',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'JobExecutionSummaries',
          description: '  The shape of a job execution',
          fields: [
            {
              name: 'jobExectionSummary',
              description: '  Summary data for a particular job execution - this spelling error is because AWS spells it incorrectly',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'JobExecutionSummary',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'status',
              description: '  The status of the job execution',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'JobStatus',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'JobExecutionSummary',
          description: '  The shape of a job execution',
          fields: [
            {
              name: 'executionNumber',
              description: '  A number that identifies a particular job execution on a particular device.',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'lastUpdatedAt',
              description: '  The timein milliseconds since the epoch, when the job execution was last updated.',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'queuedAt',
              description: '  The time, in milliseconds since the epoch, when the job execution was enqueued.',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'status',
              description: '  The time, in milliseconds since the epoch, when the job execution started.',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'JobStatus',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'JobStatus',
          description: '  enum to represent the values of order status returned by replenishment service',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'CANCELED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FAILED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'IN_PROGRESS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'QUEUED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'REJECTED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'REMOVED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SUCCEEDED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'TIMED_OUT',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'SessionConnection',
          description: '  pageable object for brushing sessions',
          fields: [
            {
              name: 'items',
              description: '  collection of brushing sessions',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'Session',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'nextToken',
              description: '  collection Paging token',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Session',
          description: '  session Data model for oralcare. Toothbrushing session',
          fields: [
            {
              name: 'deviceId',
              description: '  PLATFORM MANAGED\n device 128bit UUID v4 algorithm',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deviceType',
              description: '  hardware model as defined by the internal engineering name/number',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'DeviceType',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'receivedTime',
              description: '  time message was received by platform',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'thingName',
              description: '  thiing name',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'traceId',
              description: '  platform trace ID',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'consumerId',
              description: '  PLATFORM REQUIRED\n unique Id generated by Cognito',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessionId',
              description: '  unique Id to identify this specific brushing session',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'batteryLevel',
              description: '  DEVICE ATTRIBUTES\n percentage battery remaining at session end: 0-100',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'brushModel',
              description: '  hex code of handle model, from BLE specification docs (Decimal converted version of hex code: ex. 0x22 --> 34)',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'brushScore',
              description: '  computed brushing score based on algorithms: 0-100',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'brushingDuration',
              description: '  in seconds, from handle BLE',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'cleanTongue',
              description: '  user polled question: Did you clean your tongue? (Disabled = 0, 1 = no, 2 = yes)',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'client',
              description: '  client connecting to labs',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'Client',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'clientVersion',
              description: '  client version connecting to labs',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'coveragePercentage',
              description: '  score generated from coverage data and run through an algorithm',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'flossTeeth',
              description: '  user polled question: Did you floss? (Disabled = 0, 1 = no, 2 = yes)',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'gatewayModel',
              description: '  hex code of gateway model, from Bluetooth specification docs (Decimal converted version of hex code: ex. 0xB1 --> 177)',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'gumBleeding',
              description: '  user polled question: Did you have gum bleeding? (DISABLED, NO, YES)',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'handleSessionId',
              description: '  from handle BLE, session identifier',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'onEventCount',
              description: '  total times motor was turned on during session',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'operatingSystem',
              description: '  operating system of client connecting to labs',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'OperatingSystem',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'operatingSystemVersion',
              description: '  operating system version of client connecting to labs',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'parentDeviceId',
              description: '  gateway device 128bit UUID v4 algorithm',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'pressureDistribution',
              description: '  every second is represented with a character: (under pressure = 1, good pressure = 5, over pressure = 9)',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'pressureDuration',
              description: '  over pressure time in seconds, from handle BLE.',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'pressureEventCount',
              description: '  total times high pressure was triggered during session',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'primaryBrushingMode',
              description: '  brush mode that was used most during session',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'BrushingMode',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'rinseMouth',
              description: '  user polled question: Did you rinse? (DISABLED, NO, YES)',
              args: [],
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessionStartTime',
              description: '  start time of session provided by gateway (app, charger, etc.) as ISO 8601 Time string (Use UTC if timezone is unknown - apps should check timezone before sending session data to account for traveling users)',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSDateTime',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessionType',
              description: '  where the session is coming from',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'SessionType',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'zonedBrushTime',
              description: '  map of [zone duration], [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight, none]',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSJSON',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'zonedPressureTime',
              description: '  map of [zone duration], [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight, none]',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSJSON',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'UserPoll',
          description: '  enum for user polled question',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DISABLED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'NO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'YES',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'Client',
          description: '  client connecting to labs',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ORALB_BETAMAX',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ORALB_CONNECT',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ORALB_MAPP',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'BrushingMode',
          description: '  enum to represent different brushing modes',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DAILY_CLEAN',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'DEEP_CLEAN',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'MASSAGE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OFF',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'PRO_CLEAN',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SENSITIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SUPER_SENSITIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'TONGUE_CLEAN',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'WHITENING',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'SessionType',
          description: '  enum for session type',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'COMINO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'COMINO_CLOUD',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'COMINO_REMOTE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'DZM',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GATEWAY',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GATEWAY_COMINO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GATEWAY_GOZO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GOZO',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GOZO_REMOTE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OFFLINE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'TIMER',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableSessionFilterInput',
          description: '  session data model specific filter object',
          fields: null,
          inputFields: [
            {
              name: 'deviceId',
              description: '  filter by deviceId',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIDFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'sessionId',
              description: '  filter by sessionId',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIDFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'batteryLevel',
              description: '  filter by batteryLevel',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'brushScore',
              description: '  filter by brushScore',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'brushingDuration',
              description: '  filter by brushingDuration',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'cleanTongue',
              description: '  filter by cleanTongue',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'coveragePercentage',
              description: '  filter by coveragePercentage',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableFloatFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'flossTeeth',
              description: '  filter by flossTeeth',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'sessionStartTime',
              description: '  filter by SessionStartTime',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableStringFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gumBleeding',
              description: '  filter by gumBleeding',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'handleSessionId',
              description: '  filter by handleSessionId',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'onEventCount',
              description: '  filter by onEventCount',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'parentDeviceId',
              description: '  gateway device 128bit UUID v4 algorithm',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIDFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'pressureDistribution',
              description: '  filter by pressureDistribution',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableStringFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'pressureDuration',
              description: '  filter by pressureDuration',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'pressureEventCount',
              description: '  filter by pressureEventCount',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'primaryBrushingMode',
              description: '  filter by primaryBrushingMode',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableStringFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'rinseMouth',
              description: '  filter by rinseMouth',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIntFilterInput',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableIntFilterInput',
          description: '  generic dynamo filter input for Integer attributes',
          fields: null,
          inputFields: [
            {
              name: 'between',
              description: '  value is between first and second integers',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'contains',
              description: '  contains part of the value',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'eq',
              description: '  equal',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ge',
              description: '  greater or equal >=',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gt',
              description: '  greater than >',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'le',
              description: '  less or equal <=',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'lt',
              description: '  less than <',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ne',
              description: '  not equal',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'notContains',
              description: "  doesn't contain any of the value",
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableFloatFilterInput',
          description: '  generic dynamo filter input for Float attributes',
          fields: null,
          inputFields: [
            {
              name: 'between',
              description: '  between the range of multiple values',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Float',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'contains',
              description: '  contains part of value',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'eq',
              description: '  equal',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ge',
              description: '  greater or equal >=',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gt',
              description: '  greater than >',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'le',
              description: '  less or equal <=',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'lt',
              description: '  less than <',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ne',
              description: '  not equal',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'notContains',
              description: "  doesn't contain part of value",
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'Sort',
          description: ' enum to sort sessions by asc or desc',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ASC',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'DESC',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'User',
          description: '  data model representing the user',
          fields: [
            {
              name: 'consumerId',
              description: '  unique Id generated by Cognito',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'country',
              description: "  user's country of origin - 2 digit country code",
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createdAt',
              description: '  the date of user creation',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'doNotSell',
              description: '  consumer does not want data to be sold',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'email',
              description: '  key identifier for user account',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSEmail',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'familyName',
              description: '  last name from Cognito',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'givenName',
              description: '  first name from Cognito',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'marketingOptIn',
              description: '  marketing opt in from app sign up',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'phoneNumber',
              description: '  phone number in AWS Format from Cognito',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSPhone',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'termsAndConditions',
              description: '  terms and conditions',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'timezone',
              description: '  +/- hours from UTC',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'subscriptions',
              description: '  what subscriptions the user has setup',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: 'SubscriptionService',
                    ofType: null
                  }
                }
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'replenishmentServices',
              description: '  what replenishments the user has setup',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: 'ReplenishmentService',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'Boolean',
          description: 'Built-in Boolean',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'AWSEmail',
          description: 'The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address.',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          name: 'AWSPhone',
          description: 'The `AWSPhone` scalar type provided by AWS AppSync, represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Segments of the phone number may be whitespace delimited or hyphenated.  The number can specify a country code at the beginning. However, United States numbers without country codes are still considered to be valid.',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'SubscriptionService',
          description: '  replenishment service data model for oral care',
          fields: [
            {
              name: 'subscriptionId',
              description: '  replenishment service generated ID',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'subscriptionType',
              description: '  replenishment service type',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'SubscriptionType',
                  ofType: null
                }
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'nextOrderDate',
              description: '  next date order will be placed to replenishment service',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'status',
              description: '  current status of replenishment',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'SubscriptionStatus',
                  ofType: null
                }
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'productId',
              description: '  identifier for the product being ordered',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'SubscriptionType',
          description: '  enum to represent the values of replenishment type, replaced by replenishment',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DRS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ORALB_CONNECT_CLUB',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'SubscriptionStatus',
          description: '  enum to represent the values of replenishment status, replaced by replenishment',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ACTIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INACTIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'ReplenishmentService',
          description: '  Replenishment service data model for oral care',
          fields: [
            {
              name: 'replenishmentId',
              description: '  Replenishment service generated ID',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'replenishmentType',
              description: '  replenishment service type',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'ReplenishmentType',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'nextOrderDate',
              description: '  next date order will be placed to replenishment service',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'status',
              description: '  current status of replenishment',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'ReplenishmentStatus',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'productId',
              description: '  identifier for the product being ordered',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'quantity',
              description: '  the quantity of items contained in the productId',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'ReplenishmentType',
          description: '  enum to represent the values of replenishment type',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'DRS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ORALB_CONNECT_CLUB',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'ReplenishmentStatus',
          description: '  enum to represent the values of Replenishment status',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ACTIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INACTIVE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'ConsumableConnection',
          description: '  pageable object for consumables',
          fields: [
            {
              name: 'items',
              description: '  collection of consumables',
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'Consumable',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'nextToken',
              description: '  collection Paging token',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'TableConsumableFilterInput',
          description: '  Consumable data model specific filter object',
          fields: null,
          inputFields: [
            {
              name: 'deviceId',
              description: '  filter consumables by device ID',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableIDFilterInput',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'state',
              description: '  current state of the consumable, when a new consumable is created our mutation will automatically set this to IN_USE and set previous consumable to USED.',
              type: {
                kind: 'INPUT_OBJECT',
                name: 'TableStringFilterInput',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Mutation',
          description: '  graphql mutations: anything that creates or updates',
          fields: [
            {
              name: 'createConsumable',
              description: '  creates a consumable for the device',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'startDate',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'AWSDateTime',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'consumableType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'ConsumableType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Consumable',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createDevice',
              description: '  creates a new instance of a device associated with the signed in user, returns cert/private key if applicable',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'DeviceProvision',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createJob',
              description: '  creates a task for the device to do, with guarantees using IoT Jobs',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'operation',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'TaskType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'fileName',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'fileSize',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'force',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Boolean',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'md5',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'target',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'version',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'configType',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createSession',
              description: '  creates a new brushing session',
              args: [
                {
                  name: 'input',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'INPUT_OBJECT',
                      name: 'CreateSessionInput',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Session',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createSubscription',
              description: '  Creates a replenishment for the user based on the type provided',
              args: [
                {
                  name: 'refreshToken',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'clientId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'subscriptionType',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'SubscriptionType',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'SubscriptionService',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'createReplenishment',
              description: '  Creates a replenishment for the user based on the type provided',
              args: [
                {
                  name: 'refreshToken',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'clientId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'serialNumber',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'replenishmentType',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'ReplenishmentType',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'ReplenishmentService',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deleteDevice',
              description: '  deletes a device from the user and IoT Core',
              args: [
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'ENUM',
                      name: 'DeviceType',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deleteSession',
              description: "  deletes a applicator session from a user's account",
              args: [
                {
                  name: 'sessionId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'String',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deleteSubscription',
              description: '  delete the user from the replenishment service',
              args: [
                {
                  name: 'subscriptionId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `replenishment`'
            },
            {
              name: 'deleteReplenishment',
              description: '  delete the user from the replenishment service',
              args: [
                {
                  name: 'replenishmentId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deleteUser',
              description: '  deletes all user information, auth will be revoked',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'placeOrder',
              description: '  Tells the replenishment to place an order immediately. This will also reset the next order date',
              args: [
                {
                  name: 'replenishmentId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Order',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'updateConsumable',
              description: '  updates an existing consumable',
              args: [
                {
                  name: 'consumableId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'startDate',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'AWSDateTime',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'consumableType',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'ConsumableType',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'state',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'ConsumableState',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Consumable',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'updateDevice',
              description: '  updates any mutable attributes on the Device',
              args: [
                {
                  name: 'input',
                  description: null,
                  type: {
                    kind: 'INPUT_OBJECT',
                    name: 'UpdateDeviceInput',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Device',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'updateUser',
              description: '  updates any mutable attributes on the User',
              args: [
                {
                  name: 'input',
                  description: null,
                  type: {
                    kind: 'INPUT_OBJECT',
                    name: 'UpdateUserInput',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'User',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'updateSession',
              description: '  updates device id or parent device id on the Session',
              args: [
                {
                  name: 'sessionId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceId',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'ID',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'deviceType',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'DeviceType',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'parentDeviceId',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'ID',
                    ofType: null
                  },
                  defaultValue: null
                },
                {
                  name: 'parentDeviceType',
                  description: null,
                  type: {
                    kind: 'ENUM',
                    name: 'DeviceType',
                    ofType: null
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'Session',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessionStream',
              description: '  MQTT invoked local resolver',
              args: [
                {
                  name: 'sessionId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                },
                {
                  name: 'consumerId',
                  description: null,
                  type: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'SCALAR',
                      name: 'ID',
                      ofType: null
                    }
                  },
                  defaultValue: null
                }
              ],
              type: {
                kind: 'OBJECT',
                name: 'SessionUpdate',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'DeviceProvision',
          description: '  single device provisioning this the one-time return type',
          fields: [
            {
              name: 'cert',
              description: '  x509 Certificate returned from the platform',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'device',
              description: '  The created default device state',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'Device',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'privateKey',
              description: '  private key used to connect to AWS IoT Core',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'TaskType',
          description: '  enum to represent the options for device commands / jobs',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'CLEAR_CONFIG',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GET_STATUS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'GET_SYSLOG',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OTA_UPDATE',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'REBOOT',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'CreateSessionInput',
          description: '  input Object for creating a new brushing session',
          fields: null,
          inputFields: [
            {
              name: 'deviceId',
              description: '  PLATFORM MANAGED\n device 128bit UUID v4 algorithm',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'deviceType',
              description: '  hardware model as defined by the internal engineering name/number',
              type: {
                kind: 'ENUM',
                name: 'DeviceType',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'receivedTime',
              description: '  time message was received by platform',
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'thingName',
              description: '  thiing name',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'traceId',
              description: '  platform trace ID',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'sessionId',
              description: '  unique Id to identify this specific brushing session',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'batteryLevel',
              description: '  DEVICE ATTRIBUTES\n percentage battery remaining at session end: 0-100',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'brushingDuration',
              description: '  seconds, from handle BLE',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'brushModel',
              description: '  hex code of handle model, from BLE specification docs (Decimal converted version of hex code: ex. 0x22 --> 34)',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'brushScore',
              description: '  computed brushing score based on algorithms: 0-100',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'cleanTongue',
              description: '  user polled question: Did you clean your tongue?',
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'client',
              description: '  client connecting to labs',
              type: {
                kind: 'ENUM',
                name: 'Client',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'clientVersion',
              description: '  client version connecting to labs',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'coveragePercentage',
              description: '  score generated from coverage data and run through an algorithm',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'flossTeeth',
              description: '  user polled question: Did you floss?',
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gatewayModel',
              description: '  hex code of gateway model, from Bluetooth specification docs (Decimal converted version of hex code: ex. 0xB1 --> 177)',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'gumBleeding',
              description: '  user polled question: Did you have gum bleeding?',
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'handleSessionId',
              description: '  from handle BLE, session identifier',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'onEventCount',
              description: '  total times motor was turned on during session',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'operatingSystem',
              description: '  operating system of client connecting to labs',
              type: {
                kind: 'ENUM',
                name: 'OperatingSystem',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'operatingSystemVersion',
              description: '  operating system version of client connecting to labs',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'parentDeviceId',
              description: '  gateway device 128bit UUID v4 algorithm',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'pressureDistribution',
              description: '  every second is represented with a character: (under pressure = 1, good pressure = 5, over pressure = 9)',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'pressureDuration',
              description: '  over pressure time in seconds, from handle BLE',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'pressureEventCount',
              description: '  total times high pressure was triggered during session',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'primaryBrushingMode',
              description: '  brush mode that was used most during session',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'BrushingMode',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'rinseMouth',
              description: '  user polled question: Did you rinse?',
              type: {
                kind: 'ENUM',
                name: 'UserPoll',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'sessionStartTime',
              description: '  start time of session provided by gateway (app, charger, etc.) as ISO 8601 Time string (Use UTC if timezone is unknown - apps should check timezone before sending session data to account for traveling users)',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'AWSDateTime',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'sessionType',
              description: '  where the session is coming from',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'SessionType',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'zonedBrushTime',
              description: '  map of [zone duration], [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight, none]',
              type: {
                kind: 'SCALAR',
                name: 'AWSJSON',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'zonedPressureTime',
              description: '  map of [zone duration], [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight, none]',
              type: {
                kind: 'SCALAR',
                name: 'AWSJSON',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Order',
          description: '  order data model for oral care',
          fields: [
            {
              name: 'orderId',
              description: '  ID of the order',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'status',
              description: '  status of the order',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: 'OrderStatus',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: 'OrderStatus',
          description: '  enum to represent the values of order status returned by replenishment service',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'ORDER_INPROGRESS',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ORDER_PLACED',
              description: null,
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'UpdateDeviceInput',
          description: '  input type to update the device state',
          fields: null,
          inputFields: [
            {
              name: 'deviceId',
              description: '  PLATFORM MANAGED\n device 128bit UUID v4 algorithm',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              defaultValue: null
            },
            {
              name: 'status',
              description: '  current state of the device',
              type: {
                kind: 'ENUM',
                name: 'DeviceStatus',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'brushHeadDate',
              description: '  DEVICE ATTRIBUTES\n ISO-8601 Datetime that the brushhead was installed',
              type: {
                kind: 'SCALAR',
                name: 'AWSDateTime',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'firmwareVersion',
              description: '  firmware version of the device',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'logLevel',
              description: '  log verbosity',
              type: {
                kind: 'ENUM',
                name: 'LogLevel',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'macAddress',
              description: '  unique MAC Address for the device if applicable',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'name',
              description: '  user given device name',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'operatingSystem',
              description: '  operating system of client connecting to labs',
              type: {
                kind: 'ENUM',
                name: 'OperatingSystem',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'operatingSystemVersion',
              description: '  operating system version of client connecting to labs',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'parentDeviceId',
              description: '  gateway device 128bit UUID v4 algorithm',
              type: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'protocolVersion',
              description: '  protocol version from bluetooth specification as defined by P&G(decimal converted version of hex code)',
              type: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ringColor',
              description: '  hex code',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'ssid',
              description: '  wifi network name',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'voiceAssistantConfigured',
              description: '  voice assistant has been setup by end user',
              type: {
                kind: 'ENUM',
                name: 'VoiceAssistantStatus',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          name: 'UpdateUserInput',
          description: '  input type to update the user data',
          fields: null,
          inputFields: [
            {
              name: 'familyName',
              description: '  last name from Cognito',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'givenName',
              description: '  first name from Cognito',
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'marketingOptIn',
              description: '  marketing opt in from app sign up',
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'phoneNumber',
              description: '  phone number in AWS Format from Cognito',
              type: {
                kind: 'SCALAR',
                name: 'AWSPhone',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'termsAndConditions',
              description: '  terms and conditions',
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              defaultValue: null
            },
            {
              name: 'timezone',
              description: '  +/- hours from UTC',
              type: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null
              },
              defaultValue: null
            }
          ],
          interfaces: null,
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'SessionUpdate',
          description: '  Shallow object that is passed from MQTT update',
          fields: [
            {
              name: 'sessionId',
              description: '  unique Id to identify this specific brushing session',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'consumerId',
              description: '  unique Id to identify the specific consumer',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'ID',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: 'Subscription',
          description: '  mutations that can be subscribed to will notify subscriber when invoked, this occurs via MQTT or Websockets depending on client',
          fields: [
            {
              name: 'createdDevice',
              description: '  notifies when a new device is created',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'DeviceProvision',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'createdSession',
              description: '  notifies when a session is created',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'Session',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deletedDevice',
              description: '  notifies when a device is deleted',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deletedUser',
              description: '  notifies when a user is deleted',
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'updatedDevice',
              description: '  notifies when a device is updated',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'Device',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'sessionStream',
              description: '  MQTT invoked local resolver',
              args: [],
              type: {
                kind: 'OBJECT',
                name: 'SessionUpdate',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__Schema',
          description: 'A GraphQL Introspection defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, the entry points for query, mutation, and subscription operations.',
          fields: [
            {
              name: 'types',
              description: 'A list of all types supported by this server.',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'LIST',
                  name: null,
                  ofType: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'OBJECT',
                      name: '__Type',
                      ofType: null
                    }
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'queryType',
              description: 'The type that query operations will be rooted at.',
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: '__Type',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'mutationType',
              description: 'If this server supports mutation, the type that mutation operations will be rooted at.',
              args: [],
              type: {
                kind: 'OBJECT',
                name: '__Type',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'directives',
              description: "'A list of all directives supported by this server.",
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'LIST',
                  name: null,
                  ofType: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'OBJECT',
                      name: '__Directive',
                      ofType: null
                    }
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'subscriptionType',
              description: "'If this server support subscription, the type that subscription operations will be rooted at.",
              args: [],
              type: {
                kind: 'OBJECT',
                name: '__Type',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__Type',
          description: null,
          fields: [
            {
              name: 'kind',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'ENUM',
                  name: '__TypeKind',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'name',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'description',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'fields',
              description: null,
              args: [
                {
                  name: 'includeDeprecated',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Boolean',
                    ofType: null
                  },
                  defaultValue: 'false'
                }
              ],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__Field',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'interfaces',
              description: null,
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__Type',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'possibleTypes',
              description: null,
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__Type',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'enumValues',
              description: null,
              args: [
                {
                  name: 'includeDeprecated',
                  description: null,
                  type: {
                    kind: 'SCALAR',
                    name: 'Boolean',
                    ofType: null
                  },
                  defaultValue: 'false'
                }
              ],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__EnumValue',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'inputFields',
              description: null,
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__InputValue',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ofType',
              description: null,
              args: [],
              type: {
                kind: 'OBJECT',
                name: '__Type',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: '__TypeKind',
          description: 'An enum describing what kind of type a given __Type is',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'SCALAR',
              description: 'Indicates this type is a scalar.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OBJECT',
              description: 'Indicates this type is an object. `fields` and `interfaces` are valid fields.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INTERFACE',
              description: 'Indicates this type is an interface. `fields` and `possibleTypes` are valid fields.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'UNION',
              description: 'Indicates this type is a union. `possibleTypes` is a valid field.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ENUM',
              description: 'Indicates this type is an enum. `enumValues` is a valid field.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INPUT_OBJECT',
              description: 'Indicates this type is an input object. `inputFields` is a valid field.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'LIST',
              description: 'Indicates this type is a list. `ofType` is a valid field.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'NON_NULL',
              description: 'Indicates this type is a non-null. `ofType` is a valid field.',
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__Field',
          description: null,
          fields: [
            {
              name: 'name',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'description',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'args',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'LIST',
                  name: null,
                  ofType: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'OBJECT',
                      name: '__InputValue',
                      ofType: null
                    }
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'type',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: '__Type',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'isDeprecated',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deprecationReason',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__InputValue',
          description: null,
          fields: [
            {
              name: 'name',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'description',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'type',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: '__Type',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'defaultValue',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__EnumValue',
          description: null,
          fields: [
            {
              name: 'name',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'description',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'isDeprecated',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'deprecationReason',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          name: '__Directive',
          description: null,
          fields: [
            {
              name: 'name',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'description',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'locations',
              description: null,
              args: [],
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'ENUM',
                    name: '__DirectiveLocation',
                    ofType: null
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'args',
              description: null,
              args: [],
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'LIST',
                  name: null,
                  ofType: {
                    kind: 'NON_NULL',
                    name: null,
                    ofType: {
                      kind: 'OBJECT',
                      name: '__InputValue',
                      ofType: null
                    }
                  }
                }
              },
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'onOperation',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `locations`.'
            },
            {
              name: 'onFragment',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `locations`.'
            },
            {
              name: 'onField',
              description: null,
              args: [],
              type: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null
              },
              isDeprecated: true,
              deprecationReason: 'Use `locations`.'
            }
          ],
          inputFields: null,
          interfaces: [],
          enumValues: null,
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          name: '__DirectiveLocation',
          description: 'An enum describing valid locations where a directive can be placed',
          fields: null,
          inputFields: null,
          interfaces: null,
          enumValues: [
            {
              name: 'QUERY',
              description: 'Indicates the directive is valid on queries.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'MUTATION',
              description: 'Indicates the directive is valid on mutations.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FIELD',
              description: 'Indicates the directive is valid on fields.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FRAGMENT_DEFINITION',
              description: 'Indicates the directive is valid on fragment definitions.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FRAGMENT_SPREAD',
              description: 'Indicates the directive is valid on fragment spreads.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INLINE_FRAGMENT',
              description: 'Indicates the directive is valid on inline fragments.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SCHEMA',
              description: 'Indicates the directive is valid on a schema SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'SCALAR',
              description: 'Indicates the directive is valid on a scalar SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'OBJECT',
              description: 'Indicates the directive is valid on an object SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'FIELD_DEFINITION',
              description: 'Indicates the directive is valid on a field SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ARGUMENT_DEFINITION',
              description: 'Indicates the directive is valid on a field argument SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INTERFACE',
              description: 'Indicates the directive is valid on an interface SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'UNION',
              description: 'Indicates the directive is valid on an union SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ENUM',
              description: 'Indicates the directive is valid on an enum SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'ENUM_VALUE',
              description: 'Indicates the directive is valid on an enum value SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INPUT_OBJECT',
              description: 'Indicates the directive is valid on an input object SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            },
            {
              name: 'INPUT_FIELD_DEFINITION',
              description: 'Indicates the directive is valid on an input object field SDL definition.',
              isDeprecated: false,
              deprecationReason: null
            }
          ],
          possibleTypes: null
        }
      ],
      directives: [
        {
          name: 'include',
          description: 'Directs the executor to include this field or fragment only when the `if` argument is true',
          locations: [
            'FIELD',
            'FRAGMENT_SPREAD',
            'INLINE_FRAGMENT'
          ],
          args: [
            {
              name: 'if',
              description: 'Included when true.',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'skip',
          description: "Directs the executor to skip this field or fragment when the `if`'argument is true.",
          locations: [
            'FIELD',
            'FRAGMENT_SPREAD',
            'INLINE_FRAGMENT'
          ],
          args: [
            {
              name: 'if',
              description: 'Skipped when true.',
              type: {
                kind: 'NON_NULL',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'defer',
          description: 'This directive allows results to be deferred during execution',
          locations: [
            'FIELD'
          ],
          args: []
        },
        {
          name: 'deprecated',
          description: null,
          locations: [
            'FIELD_DEFINITION',
            'ENUM_VALUE'
          ],
          args: [
            {
              name: 'reason',
              description: null,
              type: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null
              },
              defaultValue: '"No longer supported"'
            }
          ]
        },
        {
          name: 'aws_oidc',
          description: 'Tells the service this field/object has access authorized by an OIDC token.',
          locations: [
            'OBJECT',
            'FIELD_DEFINITION'
          ],
          args: []
        },
        {
          name: 'aws_publish',
          description: 'Tells the service which subscriptions will be published to when this mutation is called. This directive is deprecated use @aws_susbscribe directive instead.',
          locations: [
            'FIELD_DEFINITION'
          ],
          args: [
            {
              name: 'subscriptions',
              description: 'List of subscriptions which will be published to when this mutation is called.',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'aws_iam',
          description: 'Tells the service this field/object has access authorized by sigv4 signing.',
          locations: [
            'OBJECT',
            'FIELD_DEFINITION'
          ],
          args: []
        },
        {
          name: 'aws_cognito_user_pools',
          description: 'Tells the service this field/object has access authorized by a Cognito User Pools token.',
          locations: [
            'OBJECT',
            'FIELD_DEFINITION'
          ],
          args: [
            {
              name: 'cognito_groups',
              description: 'List of cognito user pool groups which have access on this field',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'aws_auth',
          description: 'Directs the schema to enforce authorization on a field',
          locations: [
            'FIELD_DEFINITION'
          ],
          args: [
            {
              name: 'cognito_groups',
              description: 'List of cognito user pool groups which have access on this field',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'aws_subscribe',
          description: 'Tells the service which mutation triggers this subscription.',
          locations: [
            'FIELD_DEFINITION'
          ],
          args: [
            {
              name: 'mutations',
              description: 'List of mutations which will trigger this subscription when they are called.',
              type: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null
                }
              },
              defaultValue: null
            }
          ]
        },
        {
          name: 'aws_api_key',
          description: 'Tells the service this field/object has access authorized by an API key.',
          locations: [
            'OBJECT',
            'FIELD_DEFINITION'
          ],
          args: []
        }
      ]
    }
  }
};
