export default {
  data: {
    logs: {
      items: [
        {
          deviceType: 'SEAGATE',
          message: '{"error":"401"}',
          receivedTime: '2020-06-19T20:02:39+00:00'
        },
        {
          deviceType: 'SEAGATE',
          message: '{"error":"401"}',
          receivedTime: '2020-06-19T17:32:18+00:00'
        },
        {
          deviceType: 'SEAGATE',
          message: '{"error":"401"}',
          receivedTime: '2020-06-19T17:02:43+00:00'
        },
        {
          deviceType: 'SEAGATE',
          message: '{"error":"401"}',
          receivedTime: '2020-06-19T16:32:06+00:00'
        }
      ]
    }
  }
};
