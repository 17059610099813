export const getDeviceStatusesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'FACTORY'
        },
        {
          name: 'PROVISIONED'
        }
      ]
    }
  }
};

export const getOralBVoiceConfigsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DISABLED'
        },
        {
          name: 'ENABLED'
        }
      ]
    }
  }
};

export const getOralBBrushingModeTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DAILY_CLEAN'
        },
        {
          name: 'DEEP_CLEAN'
        },
        {
          name: 'MASSAGE'
        },
        {
          name: 'OFF'
        },
        {
          name: 'PRO_CLEAN'
        },
        {
          name: 'SENSITIVE'
        },
        {
          name: 'SUPER_SENSITIVE'
        },
        {
          name: 'TONGUE_CLEAN'
        },
        {
          name: 'WHITENING'
        }
      ]
    }
  }
};

export const getTaskTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'CLEAR_CONFIG'
        },
        {
          name: 'GET_STATUS'
        },
        {
          name: 'GET_SYSLOG'
        },
        {
          name: 'OTA_UPDATE'
        },
        {
          name: 'REBOOT'
        }
      ]
    }
  }
};

export const getConsumablesStatesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'IN_USE'
        },
        {
          name: 'REPLACED'
        }
      ]
    }
  }
};

export const getOralBConsumablesTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'CROSS_ACTION'
        },
        {
          name: 'FLOSS_ACTION'
        },
        {
          name: 'OTHER'
        },
        {
          name: 'PRECISION'
        },
        {
          name: 'PRO_GUM'
        },
        {
          name: 'SENSITIVE'
        },
        {
          name: 'THREE_D_WHITE'
        }
      ]
    }
  }
};

export const getLabsDeviceStatusesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'ERROR'
        },
        {
          name: 'FACTORY'
        },
        {
          name: 'PROVISIONED'
        }
      ]
    }
  }
};

export const getBraunSessionTypeEnumsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'PLAN'
        },
        {
          name: 'RECORDED'
        }
      ]
    }
  }
};

export const getBraunLogLevelEnumsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DEBUG'
        },
        {
          name: 'ERROR'
        },
        {
          name: 'INFO'
        },
        {
          name: 'WARN'
        }
      ]
    }
  }
};

export const getBraunClientEnumsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DEVELOPER_DASHBOARD'
        },
        {
          name: 'SILK_EXPERT_PRO'
        }
      ]
    }
  }
};

export const GetBraunSessionStatusEnumsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'ASSIGNED'
        },
        {
          name: 'CANCELLED'
        },
        {
          name: 'DONE'
        },
        {
          name: 'PLANNED'
        },
        {
          name: 'RECORDED'
        },
        {
          name: 'SKIPPED'
        },
        {
          name: 'STARTED'
        },
        {
          name: 'WITHDRAWN'
        }
      ]
    }
  }
};
