// this mutation is for G558 only as of 07/06/20
export const CreateGilletteDeviceMutation = `
  mutation CreateDevice($deviceId: ID!, $deviceType: DeviceType!) {
    createDevice(deviceId: $deviceId, deviceType: $deviceType) {
      device {
        deviceId
        deviceType
      }
    }
  }
`;

// this mutation is for HG2 only as of 07/06/20
export const ClaimGilletteDeviceMutation = `
mutation ClaimGilletteDevice(
  $deviceType: DeviceType!,
  $deviceId: ID!
) {
  claimDevice(
    deviceType: $deviceType,
    deviceId: $deviceId
  ) {
    deviceId
    deviceType
  }
}`;

export const GetGilletteDeviceQuery = `query Device($deviceId: String!) {
  device(deviceId: $deviceId) {
    deviceId
    deviceType
    receivedTime
    stateId
    thingName
    traceId
    consumerId
    status
    baseConnectColor
    baseDockedColor
    baseDownloadColor
    baseFirmwareVersion
    baseReadyColor
    baseUploadColor
    cartridgeUse
    cellBand
    cellDetail
    cellStrength
    firmwareMode
    handleChargingColor
    handleDocked
    handleDockedColor
    handleDownloadColor
    handleFirmwareVersion
    handleId
    handleMode
    handlePressureHighColor
    handlePressureLowColor
    handlePressureMedColor
    lastCartridgeReplacement
    pressureBaseline
    pressureHysteresis
    pressureThreshold1
    pressureThreshold2
    pressureThreshold2ModeHigh
    pressureThreshold2ModeLow
    pressureThreshold2ModeMedium
    pressureThresholdStroke
    sensorRangeAcceleration
    sensorRangeGyro
    sensorRangeMag
  }
}
`;
export const GetGilletteDevicesQuery = `query Devices {
  devices {
    deviceId
    deviceType
    receivedTime
    stateId
    thingName
    traceId
    consumerId
    status
    baseConnectColor
    baseDockedColor
    baseDownloadColor
    baseFirmwareVersion
    baseReadyColor
    baseUploadColor
    cartridgeUse
    cellBand
    cellDetail
    cellStrength
    firmwareMode
    handleChargingColor
    handleDocked
    handleDockedColor
    handleDownloadColor
    handleFirmwareVersion
    handleId
    handleMode
    handlePressureHighColor
    handlePressureLowColor
    handlePressureMedColor
    pressureBaseline
    pressureHysteresis
    pressureThreshold1
    pressureThreshold2
    pressureThresholdStroke
    sensorRangeAcceleration
    sensorRangeGyro
    sensorRangeMag
  }
}
`;

export const UpdateGilletteDeviceMutation = `
  mutation update(
    $device: UpdateDeviceInput
  ) {
    updateDevice(
      input: $device
    ){
      createdBy
      deviceId
      deviceType
      receivedTime
      stateId
      thingName
      traceId
      consumerId
      status
      baseConnectColor
      baseDockedColor
      baseDownloadColor
      baseFirmwareVersion
      baseReadyColor
      baseUploadColor
      cartridgeUse
      cellBand
      cellDetail
      cellStrength
      firmwareMode
      handleChargingColor
      handleDocked
      handleDockedColor
      handleDownloadColor
      handleFirmwareVersion
      handleId
      handleMode
      handlePressureHighColor
      handlePressureLowColor
      handlePressureMedColor
      pressureBaseline
      pressureHysteresis
      pressureThreshold1
      pressureThreshold2
      pressureThresholdStroke
      sensorRangeAcceleration
      sensorRangeGyro
      sensorRangeMag
    }
  }
`;

export const UnclaimGilletteDeviceMutation = `
  mutation unclaimDevice(
    $deviceId: ID!,
    $deviceType: DeviceType!
  ) {
    unclaimDevice(
      deviceId: $deviceId,
      deviceType: $deviceType
    )
  }
`;

export const DeleteGilletteDeviceMutation = `
  mutation deleteDevice(
    $deviceId: ID!,
    $deviceType: DeviceType!
  ) {
    deleteDevice(
      deviceId: $deviceId,
      deviceType: $deviceType
    )
  }
`;

export const GetGilletteSessionsQuery = `query Sessions {
  sessions(start: "1970-01-01T00:00:00+00:00", end: "2999-01-01T00:00:00+00:00", limit: 1000) {
    items {
      cartridgeChange
      cartridgeUse
      consumerId
      diagnosticResult
      deviceId
      deviceType
      errorCode
      errorMessage
      handleMode
      modelVersion
      percentageOverThreshold
      receivedTime
      sessionDuration
      sessionId
      sessionStartTime
      sessionStrokes
      sessionStrokeCount
      thingName
      traceId
      threshold1
      threshold2
      zonedPressureTime
      zonedRepeatTime
    }
  }
}`;

export const CreateGilletteSessionMutation = `
  mutation createSession(
    $session: CreateSessionInput!,
  ){
    createSession(
      input: $session
    ){
      deviceId
    }
  }`;

export const DeleteGilletteUser = `mutation DeleteUser {
  deleteUser
}
`;

export const UpdateGilletteUser = `mutation UpdateUser($input: UpdateUserInput) {
  updateUser(input: $input) {
    cartridgeCount
    cartridgeLifeThresholds
    consumerId
    doNotSell
    email
    familyName
    givenName
    marketingOptIn
    termsAndConditions
    timezone
    userSurvey
  }
}
`;

export const DeleteGilletteSessionMutation = `
mutation deleteSession($sessionId: ID!) {
  deleteSession(sessionId:$sessionId)
}`;

export const GetGilletteDeviceTypesQuery = `
query types {
  __type(name: "DeviceType") {
    enumValues {
      name
    }
  }
}`;

export const GetGilletteDeviceStatusesQuery = `
query types {
  __type(name: "DeviceStatus") {
    enumValues {
      name
    }
  }
}`;

export const GetGilletteDeviceFirmwaremodeQuery = `
query types {
  __type(name: "FirmwareMode") {
    enumValues {
      name
    }
  }
}`;

export const GetGilletteDeviceHandleDockedQuery = `
query types {
  __type(name: "HandleDocked") {
    enumValues {
      name
    }
  }
}`;

export const GetGilletteDeviceHandleModeQuery = `
query types {
  __type(name: "HandleMode") {
    enumValues {
      name
    }
  }
}`;
export const GetGilletteCartridgeChangeQuery = `
query types {
  __type(name: "CartridgeChange") {
    enumValues {
      name
    }
  }
}`;
export const GetGilletteDiagnosticResultQuery = `
query types {
  __type(name: "DiagnosticResult") {
    enumValues {
      name
    }
  }
}`;
