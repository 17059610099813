import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './plugins/fontawesome';
import './registerBaseComponents';
import './registerServiceWorker';
import './assets/css/tailwind.css';
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import router from './router';
import Vuelidate from 'vuelidate';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueGoodTablePlugin from 'vue-good-table';
import VueJsonPretty from 'vue-json-pretty';
import VueCookies from 'vue-cookies';
import { Datetime } from 'vue-datetime';
import moment from 'moment';

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';
import { Tabs, Tab } from 'vue-tabs-component';

import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';

// Amplify authenticator configuration
import configureAmplify from '@/util/configureAmplify';

// font-awesome library
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCopy,
  faEnvelope,
  faLock,
  faPhone,
  faCalendar,
  faPlane,
  faTabletAlt,
  faAddressCard,
  faSignOutAlt,
  faBars,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTimesCircle,
  faList,
  faPassport,
  faCloud
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.prototype.moment = moment;

Vue.use(Vuelidate);
Vue.use(VueSidebarMenu);
Vue.use(VueGoodTablePlugin);
Vue.use(VueCookies);
Vue.use(Datetime);

Vue.component('vue-json-pretty', VueJsonPretty);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
const ampConfig = VueCookies.get('ampConfig');
const currBrand = localStorage.getItem('currentBrand');
if (!currBrand) {
  localStorage.setItem('currentBrand', 'Oral-B NA');
}
if (ampConfig) {
  configureAmplify(ampConfig);
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });
} else {
  configureAmplify();
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });
}
library.add(
  faCopy,
  faEnvelope,
  faLock,
  faPhone,
  faCalendar,
  faPlane,
  faTabletAlt,
  faAddressCard,
  faSignOutAlt,
  faBars,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTimesCircle,
  faList,
  faPassport,
  faCloud
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.silent = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
