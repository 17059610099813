export const getConsumablesData = {
  data: {
    consumables: {
      items: [
        {
          consumableId: '9e5db908-523b-49b3-8d90-8c667e7404e4',
          consumableType: 'CROSS_ACTION',
          deviceId: 'abc123',
          startDate: '2020-11-10T01:24:32+00:00',
          state: 'IN_USE',
          totalSessionCount: 0,
          totalSessionDurationSeconds: 0
        },
        {
          consumableId: 'b8fa3bc0-0db3-4d60-9f30-1b87b07b0b3b',
          consumableType: 'PRECISION',
          deviceId: 'abc123',
          startDate: '2020-11-10T01:24:32+00:00',
          state: 'IN_USE',
          totalSessionCount: 0,
          totalSessionDurationSeconds: 0
        }
      ]
    }
  }
};

export const createConsumablesData = {
  data: {
    createConsumable: {
      consumableId: 'e038806e-dede-4b95-be72-64adb3baa535',
      deviceId: 'abc123'
    }
  }
};
