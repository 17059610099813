export const createJobsData = {
  data: {
    createJob: 'Job created'
  }
};

export const getJobsData = {
  data: {
    allJobs: {
      executionSummaries: [
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-11T00:16:31+00:00',
            queuedAt: '2020-11-11T00:16:31+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T20:39:19+00:00',
            queuedAt: '2020-11-10T20:39:19+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        }, {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T20:29:04+00:00',
            queuedAt: '2020-11-10T20:29:04+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        }, {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T19:26:54+00:00',
            queuedAt: '2020-11-10T19:26:54+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T14:21:58+00:00',
            queuedAt: '2020-11-10T14:21:58+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T03:59:34+00:00',
            queuedAt: '2020-11-10T03:59:34+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T03:57:53+00:00',
            queuedAt: '2020-11-10T03:57:53+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T02:39:34+00:00',
            queuedAt: '2020-11-10T02:39:34+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T02:00:12+00:00',
            queuedAt: '2020-11-10T02:00:12+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        },
        {
          jobExectionSummary: {
            executionNumber: 1,
            lastUpdatedAt: '2020-11-10T01:52:11+00:00',
            queuedAt: '2020-11-10T01:52:11+00:00',
            status: 'QUEUED',
            description: 'test desciption',
            document: 'test-link.com'
          },
          status: 'QUEUED'
        }]
    }
  }
};
