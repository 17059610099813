import { Auth } from 'aws-amplify';

export async function handleAuthRedirects (to, from, next) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  Auth.currentAuthenticatedUser()
    .then(() => {
      if (to.name === 'auth') next({ name: 'homepage' });
      else next();
    })
    .catch(() => {
      if (requiresAuth) { next('/auth'); } else next();
    });
}
