// queries

export const GetLabsDeviceTypesQuery = `
query MyQuery {
  deviceTypes {
    name
  }
}
`;
export const GetLabsDeviceQuery = `
  query Device($deviceId: String!) {
    device(deviceId: $deviceId) {
      createdBy
      deviceId
      deviceType
      receivedTime
      stateId
      thingName
      traceId
      consumerId
      status
      macAddress
      firmwareVersion
      logLevel
      message
      protocolVersion
      rssi
    }
  }
`;
export const GetLabsDevicesQuery = `
  query Devices {
    devices {
      createdBy
      deviceId
      deviceType
      receivedTime
      stateId
      thingName
      traceId
      consumerId
      status
      macAddress
      firmwareVersion
      logLevel
      message
      protocolVersion
      rssi
    }
  }
`;
export const GetLabsSessionsQuery = `
  query Sessions {
    sessions(start: "1970-01-01T00:00:00+00:00", end: "2999-01-01T00:00:00+00:00", limit: 1000){
      items {
        deviceId
        deviceType
        receivedTime
        thingName
        traceId
        consumerId
        sessionId
        client
        clientVersion
        message
        operatingSystem
        operatingSystemVersion
        sessionStartTime
        sessionType
      }
      nextToken
    }
  }
`;

// mutations

export const CreateLabsDeviceMutation = `
  mutation CreateDevice($deviceId: ID!, $deviceType: String!) {
    createDevice(deviceId: $deviceId, deviceType: $deviceType) {
      cert
      device {
        createdBy
        deviceId
        deviceType
        receivedTime
        stateId
        thingName
        traceId
        consumerId
        status
        macAddress
        firmwareVersion
        logLevel
        message
        protocolVersion
        rssi
      }
      privateKey
    }
  }
`;
export const CreateLabsSessionMutation = `
  mutation createSession(
    $input: CreateSessionInput!
  ) {
    createSession(input: $input) {
      deviceId
      deviceType
      receivedTime
      thingName
      traceId
      consumerId
      sessionId
      client
      clientVersion
      message
      operatingSystem
      operatingSystemVersion
      sessionStartTime
      sessionType
    }
  }
`;
export const DeleteLabsDeviceMutation = `
  mutation DeleteDevice($deviceId: ID!, $deviceType: String!) {
    deleteDevice(deviceId: $deviceId, deviceType: $deviceType)
  }
`;
export const DeleteLabsSessionMutation = `
  mutation DeleteSession($sessionId: String!) {
    deleteSession(sessionId: $sessionId)
  }
`;
export const DeleteLabsUser = `
  mutation DeleteUser {
    deleteUser
  }
`;
export const UpdateLabsDeviceMutation = `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      createdBy
      deviceId
      deviceType
      receivedTime
      stateId
      thingName
      traceId
      consumerId
      status
      macAddress
      firmwareVersion
      logLevel
      message
      protocolVersion
      rssi
    }
  }
`;
export const UpdateLabsUser = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      consumerId
      country
      createdAt
      doNotSell
      email
      familyName
      givenName
      marketingOptIn
      phoneNumber
      termsAndConditions
      timezone
    }
  }
`;

export const CreateLabsOTA = `
mutation createJob(
  $deviceId: ID!
  $deviceType: String!
  $fileName: String!
  $fileSize: String
  $md5: String
  $target: String
  $version: String!
  $force: Boolean,
  $operation: TaskType!
) {
    createJob(
      deviceId: $deviceId,
      deviceType: $deviceType,
      fileName: $fileName,
      fileSize: $fileSize,
      md5: $md5,
      target: $target,
      version: $version,
      force: $force,
      operation: $operation
    )
  }
`;

export const GetAllLabsJobs = `
query AllJobs(
  $deviceId: String!
  $deviceType: String!
){
  allJobs(deviceId: $deviceId, deviceType: $deviceType) {
    executionSummaries {
      jobExectionSummary {
        executionNumber
        lastUpdatedAt
        queuedAt
        status
        jobId
        description
        document
      }
      status
    }
  }
}
`;
