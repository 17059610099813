// LOGS

export const logsColumns = [
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'AWSDateTime',
    width: '300px'
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id',
    width: '300px'
  },
  {
    label: 'Message',
    field: 'message',
    type: 'object',
    width: '300px'
  },
  {
    label: 'Status Code',
    field: 'statusCode',
    type: 'string',
    width: '300px'
  }
];

// VALIDATIONS

export const validationsColumns = [
  {
    label: 'Device ID',
    field: 'data.deviceId',
    type: 'id',
    width: '200px'
  },
  {
    label: 'Time',
    field: 'time',
    type: 'AWSDateTime',
    width: '200px'
  },
  {
    label: 'Table',
    field: 'data.table',
    type: 'string',
    width: '200px'
  },
  {
    label: 'State ID',
    field: 'data.stateId',
    type: 'string',
    width: '200px'
  },
  {
    label: 'Session ID',
    field: 'data.sessionId',
    type: 'string',
    width: '200px'
  },
  {
    label: 'Field',
    field: 'data.field',
    type: 'string',
    width: '200px'
  },
  {
    label: 'Value',
    field: 'data.fieldValue',
    type: 'string',
    width: '200px'
  },
  {
    label: 'Message',
    field: 'data.message',
    type: 'string',
    width: '200px'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

// INTEGRATIONS

export const integrationsColumns = [
  {
    label: 'Integration ID',
    field: 'id',
    type: 'id',
    width: '500px'
  },
  {
    label: 'Name',
    field: 'name',
    type: 'string',
    width: '300px'
  },
  {
    label: 'Sensor',
    field: 'sensor',
    type: 'string',
    width: '300px'
  },
  {
    label: 'Revoke Access',
    field: 'revoke',
    type: 'integrationLink',
    width: '200px'
  }
];

// DEVICES

export const oralbDeviceColumns = [
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id'
  },
  {
    label: 'Mac Address',
    field: 'macAddress',
    type: 'string'
  },
  {
    label: 'Ring Color',
    field: 'ringColor',
    type: 'colorHex'
  },
  {
    label: 'Ssid',
    field: 'ssid',
    type: 'string'
  },
  {
    label: 'Name',
    field: 'name',
    type: 'string'
  },
  {
    label: 'Status',
    field: 'status',
    type: 'object'
  },
  {
    label: 'Protocol Version',
    field: 'protocolVersion',
    type: 'number'
  },
  {
    label: 'Firmware Version',
    field: 'firmwareVersion',
    type: 'string'
  },
  {
    label: 'Voice Assistant Configured',
    field: 'voiceAssistantConfigured',
    type: 'string'
  },
  {
    label: 'RSSI',
    field: 'rssi',
    type: 'number'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

// SINGLE DEVICE

export const oralbSingleDeviceColumns = [
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id'
  },
  {
    label: 'Mac Address',
    field: 'macAddress',
    type: 'string'
  },
  {
    label: 'Ring Color',
    field: 'ringColor',
    type: 'colorHex'
  },
  {
    label: 'Ssid',
    field: 'ssid',
    type: 'string'
  },
  {
    label: 'Name',
    field: 'name',
    type: 'string'
  },
  {
    label: 'Status',
    field: 'status',
    type: 'object'
  },
  {
    label: 'Protocol Version',
    field: 'protocolVersion',
    type: 'number'
  },
  {
    label: 'Firmware Version',
    field: 'firmwareVersion',
    type: 'string'
  },
  {
    label: 'Voice Assistant Configured',
    field: 'voiceAssistantConfigured',
    type: 'number'
  },
  {
    label: 'RSSI',
    field: 'rssi',
    type: 'number'
  }
];

export const opteDeviceColumns = [
  {
    label: 'Thing Name',
    field: 'thingName',
    type: 'string'
  },
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id'
  },
  {
    label: 'Base Hardware Model',
    field: 'baseHardwareModel',
    type: 'string'
  },
  {
    label: 'Base Hardware Version',
    field: 'baseHardwareVersion',
    type: 'string'
  },
  {
    label: 'Base Firmware Version',
    field: 'baseFirmwareVersion',
    type: 'string'
  },
  {
    label: 'Base Firmware Build Date',
    field: 'baseFirmwareBuildDate',
    type: 'AWSDateTime'
  },
  {
    label: 'Base Firmware Build Options',
    field: 'baseFirmwareBuildOptions',
    type: 'string'
  },
  {
    label: 'Base Bootcount',
    field: 'baseBootcount',
    type: 'string'
  },
  {
    label: 'Base Uptime',
    field: 'baseUptime',
    type: 'string'
  },
  {
    label: 'Ntp Server',
    field: 'ntpServer',
    type: 'string'
  },
  {
    label: 'Rssi',
    field: 'rssi',
    type: 'number'
  },
  {
    label: 'Base Storage',
    field: 'baseStorage',
    type: 'string'
  },
  {
    label: 'Wand Hardware Model',
    field: 'wandHardwareModel',
    type: 'string'
  },
  {
    label: 'Wand Hardware Version',
    field: 'wandHardwareVersion',
    type: 'string'
  },
  {
    label: 'Wand Firmware Build Options',
    field: 'wandFirmwareBuildOptions',
    type: 'string'
  },
  {
    label: 'Wand Bootcount',
    field: 'wandBootcount',
    type: 'string'
  },
  {
    label: 'Wand Uptime',
    field: 'wandUptime',
    type: 'string'
  },
  {
    label: 'Display Inversion State',
    field: 'displayInversionState',
    type: 'string'
  },
  {
    label: 'Idle Timeout',
    field: 'idleTimeout',
    type: 'string'
  },
  {
    label: 'Drops Per Click',
    field: 'dropsPerClick',
    type: 'string'
  },
  {
    label: 'Temp Shutdown Low',
    field: 'tempShutdownLow',
    type: 'string'
  },
  {
    label: 'Temp Cutoff Low',
    field: 'tempCutoffLow',
    type: 'string'
  },
  {
    label: 'Temp Cutoff High',
    field: 'tempCutoffHigh',
    type: 'string'
  },
  {
    label: 'Camera Offset Horz',
    field: 'cameraOffsetHorz',
    type: 'string'
  },
  {
    label: 'Camera Offset Vert',
    field: 'cameraOffsetVert',
    type: 'string'
  },
  {
    label: 'Camera Rotation',
    field: 'cameraRotation',
    type: 'string'
  },
  {
    label: 'Ab Timeout Idle',
    field: 'abTimeoutIdle',
    type: 'string'
  },
  {
    label: 'Ab Timeout Grace',
    field: 'abTimeoutGrace',
    type: 'string'
  },
  {
    label: 'Ab Timeout Silence',
    field: 'abTimeoutSilence',
    type: 'string'
  },
  {
    label: 'Skin Row Start',
    field: 'skinRowStart',
    type: 'string'
  },
  {
    label: 'Image Width',
    field: 'imageWidth',
    type: 'string'
  },
  {
    label: 'Image Height',
    field: 'imageHeight',
    type: 'string'
  },
  {
    label: 'Skin Image Height',
    field: 'skinImageHeight',
    type: 'string'
  },
  {
    label: 'Ror Height',
    field: 'rorHeight',
    type: 'string'
  },
  {
    label: 'Roi Height',
    field: 'roiHeight',
    type: 'string'
  },
  {
    label: 'Roi Total Width',
    field: 'roiTotalWidth',
    type: 'string'
  },
  {
    label: 'Auto Et Enable',
    field: 'autoEtEnable',
    type: 'string'
  },
  {
    label: 'App Offset X',
    field: 'appOffsetX',
    type: 'string'
  },
  {
    label: 'App Offset Y',
    field: 'appOffsetY',
    type: 'string'
  },
  {
    label: 'Camera Rotational Angle',
    field: 'cameraRotationalAngle',
    type: 'string'
  },
  {
    label: 'Roller Window Offset X',
    field: 'rollerWindowOffsetX',
    type: 'string'
  },
  {
    label: 'Roller Window Offset Y',
    field: 'rollerWindowOffsetY',
    type: 'string'
  },
  {
    label: 'Roller Window Width',
    field: 'rollerWindowWidth',
    type: 'string'
  },
  {
    label: 'Roller Window Height',
    field: 'rollerWindowHeight',
    type: 'string'
  },
  {
    label: 'Heater Timeout',
    field: 'heaterTimeout',
    type: 'string'
  },
  {
    label: 'Nozzle Prefire Delta',
    field: 'nozzlePrefireDelta',
    type: 'string'
  },
  {
    label: 'Nozzle Prefire Width',
    field: 'nozzlePrefireWidth',
    type: 'string'
  },
  {
    label: 'Nozzle Pulse Delta',
    field: 'nozzlePulseDelta',
    type: 'string'
  },
  {
    label: 'Nozzle Pulse Width',
    field: 'nozzlePulseWidth',
    type: 'string'
  },
  {
    label: 'Kws Freq On Skin',
    field: 'kwsFreqOnSkin',
    type: 'string'
  },
  {
    label: 'Kws Freq Off Skin',
    field: 'kwsFreqOffSkin',
    type: 'string'
  },
  {
    label: 'Intensity',
    field: 'intensity',
    type: 'string'
  },
  {
    label: 'Roller Change',
    field: 'rollerChange',
    type: 'string'
  },
  {
    label: 'Analog Gain',
    field: 'analogGain',
    type: 'string'
  },
  {
    label: 'Blue Led Level',
    field: 'blueLedLevel',
    type: 'string'
  },
  {
    label: 'Max Drops',
    field: 'maxDrops',
    type: 'string'
  },
  {
    label: 'Min Drops',
    field: 'minDrops',
    type: 'string'
  },
  {
    label: 'Threshold',
    field: 'threshold',
    type: 'string'
  },
  {
    label: 'Liftoff',
    field: 'liftoff',
    type: 'string'
  },
  {
    label: 'Cart ID',
    field: 'cartId',
    type: 'id'
  },
  {
    label: 'Base Storage',
    field: 'baseStorage',
    type: 'string'
  },
  {
    label: 'Roller Window Width',
    field: 'rollerWindowWidth',
    type: 'string'
  },
  {
    label: 'Cart Drops Print Lifetime',
    field: 'cartDropsPrintLifetime',
    type: 'string'
  },
  {
    label: 'Cart Drops Purge Lifetime',
    field: 'cartDropsPurgeLifetime',
    type: 'string'
  },
  {
    label: 'Cart Drops Kms Out Lifetime',
    field: 'cartDropsKmsOutLifetime',
    type: 'string'
  },
  {
    label: 'Cart Drops Kms In Lifetime',
    field: 'cartDropsKmsInLifetime',
    type: 'string'
  },
  {
    label: 'Cart Time On Skin Lifetime',
    field: 'cartTimeOnSkinLifetime',
    type: 'string'
  }
];

export const gilletteDeviceColumns = [
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'number'
  },
  {
    label: 'Base Cartidge Life High',
    field: 'baseCartidgeLifeHigh',
    type: 'string'
  },
  {
    label: 'Base Cartidge Life Indicator',
    field: 'baseCartidgeLifeIndicator',
    type: 'string'
  },
  {
    label: 'Base Cartidge Life Init',
    field: 'baseCartidgeLifeInit',
    type: 'string'
  },
  {
    label: 'Base Cartidge Life Low',
    field: 'baseCartidgeLifeLow',
    type: 'string'
  },
  {
    label: 'Base Cartidge Life Med',
    field: 'baseCartidgeLifeMed',
    type: 'string'
  },
  {
    label: 'Base Connect Color',
    field: 'baseConnectColor',
    type: 'colorArray'
  },
  {
    label: 'Base Docked Color',
    field: 'baseDockedColor',
    type: 'colorArray'
  },
  {
    label: 'Base Content Led Left',
    field: 'baseContentLedLeft',
    type: 'string'
  },
  {
    label: 'Base Content Led Right',
    field: 'baseContentLedRgiht',
    type: 'string'
  },
  {
    label: 'Base Download Color',
    field: 'baseDownloadColor',
    type: 'colorArray'
  },
  {
    label: 'BaseFirmwareVersion',
    field: 'baseFirmwareVersion',
    type: 'string'
  },
  {
    label: 'Base Hardware Version',
    field: 'baseHardwareVersion',
    type: 'string'
  },
  {
    label: 'Base ID',
    field: 'baseId',
    type: 'id'
  },
  {
    label: 'Base Ready Color',
    field: 'baseReadyColor',
    type: 'colorArray'
  },
  {
    label: 'Base Upload Color',
    field: 'baseUploadColor',
    type: 'colorArray'
  },
  {
    label: 'Cartridge Use',
    field: 'cartridgeUse',
    type: 'string'
  },
  {
    label: 'Cell Band',
    field: 'cellBand',
    type: 'string'
  },
  {
    label: 'Cell Detail',
    field: 'cellDetail',
    type: 'string'
  },
  {
    label: 'Cell Strength',
    field: 'cellStrength',
    type: 'string'
  },
  {
    label: 'Created By',
    field: 'createdBy',
    type: 'string'
  },
  {
    label: 'Consumer ID',
    field: 'consumerId',
    type: 'id'
  },
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Firmware Mode',
    field: 'firmwareMode',
    type: 'string'
  },
  {
    label: 'Handle Charging Color',
    field: 'handleChargingColor',
    type: 'colorArray'
  },
  {
    label: 'Handle Docked',
    field: 'handleDocked',
    type: 'string'
  },
  {
    label: 'Handle Docked Color',
    field: 'handleDockedColor',
    type: 'colorArray'
  },
  {
    label: 'Handle Download Color',
    field: 'handleDownloadColor',
    type: 'colorArray'
  },
  {
    label: 'Handle Firmware Version',
    field: 'handleFirmwareVersion',
    type: 'string'
  },
  {
    label: 'Handle ID',
    field: 'handleId',
    type: 'id'
  },
  {
    label: 'Handle Mode',
    field: 'handleMode',
    type: 'string'
  },
  {
    label: 'Handle Pressure High Color',
    field: 'handlePressureHighColor',
    type: 'colorArray'
  },
  {
    label: 'Handle Pressure Low Color',
    field: 'handlePressureLowColor',
    type: 'colorArray'
  },
  {
    label: 'Handle Pressure Med Color',
    field: 'handlePressureMedColor',
    type: 'colorArray'
  },
  {
    label: 'Last Cartridge Replacement',
    field: 'lastCartridgeReplacement',
    type: 'string'
  },
  {
    label: 'Pressure Baseline',
    field: 'pressureBaseline',
    type: 'string'
  },
  {
    label: 'Pressure Hysteresis',
    field: 'pressureHysteresis',
    type: 'string'
  },
  {
    label: 'Pressure Threshold1',
    field: 'pressureThreshold1',
    type: 'string'
  },
  {
    label: 'Pressure Threshold2',
    field: 'pressureThreshold2',
    type: 'string'
  },
  {
    label: 'Pressure Threshold Stroke',
    field: 'pressureThresholdStroke',
    type: 'string'
  },
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Sensor Range Acceleration',
    field: 'sensorRangeAcceleration',
    type: 'string'
  },
  {
    label: 'Sensor Range Gyro',
    field: 'sensorRangeGyro',
    type: 'string'
  },
  {
    label: 'Sensor Range Mag',
    field: 'sensorRangeMag',
    type: 'string'
  },
  {
    label: 'stateId',
    field: 'stateId',
    type: 'string'
  },
  {
    label: 'Status',
    field: 'status',
    type: 'object'
  },
  {
    label: 'Thing Name',
    field: 'thingName',
    type: 'string'
  },
  {
    label: 'Trace ID',
    field: 'traceId',
    type: 'id'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const labsDeviceColumns = [
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'string'
  },
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Thing Name',
    field: 'thingName',
    type: 'string'
  },
  {
    label: 'Consumer ID',
    field: 'consumerId',
    type: 'string'
  },
  {
    label: 'Created By',
    field: 'createdBy',
    type: 'string'
  },
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'AWSDateTime'
  },
  {
    label: 'State ID',
    field: 'stateId',
    type: 'string'
  },
  {
    label: 'ID 1',
    field: 'id1',
    type: 'string'
  },
  {
    label: 'Message',
    field: 'message',
    type: 'string'
  },
  {
    label: 'Status',
    field: 'status',
    type: 'string'
  },
  {
    label: 'Trace ID',
    field: 'traceId',
    type: 'string'
  },
  {
    label: 'Consumer ID',
    field: 'consumerId',
    type: 'string'
  },
  {
    label: 'RSSI',
    field: 'rssi',
    type: 'string'
  },
  {
    label: 'Mac Address',
    field: 'macAddress',
    type: 'string'
  },
  {
    label: 'Firmware Version',
    field: 'firmwareVersion',
    type: 'string'
  },
  {
    label: 'Protocol Version',
    field: 'protocolVersion',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const braunDeviceColumns = [
  {
    field: 'deviceId',
    label: 'Device ID',
    type: 'number'
  },

  {
    field: 'deviceType',
    label: 'Device Type',
    type: 'string'
  },
  {
    field: 'createdBy',
    label: 'Created By',
    type: 'string'
  },
  {
    field: 'calibration',
    label: 'Calibration',
    type: 'string'
  },
  {
    field: 'consumerId',
    label: 'Consumer ID',
    type: 'string'
  },
  {
    field: 'features',
    label: 'Features',
    type: 'string'
  },
  {
    field: 'firmwareVersion',
    label: 'Firmware Version',
    type: 'string'
  },
  {
    field: 'firmwareVersionIPL',
    label: 'Firmware Version IPL',
    type: 'string'
  },
  {
    field: 'hardwareModel',
    label: 'Hardware Model',
    type: 'string'
  },
  {
    field: 'hardwareVersion',
    label: 'Hardware Version',
    type: 'string'
  },
  {
    field: 'logLevel',
    label: 'Log Level',
    type: 'string'
  },
  {
    field: 'mountedHead',
    label: 'Mounted Head',
    type: 'string'
  },
  {
    field: 'operatingSystem',
    label: 'Operating System',
    type: 'string'
  },
  {
    field: 'operatingSystemVersion',
    label: 'Operating System Version',
    type: 'string'
  },
  {
    field: 'pressureOffset',
    label: 'Pressure Offset',
    type: 'string'
  },
  {
    field: 'receivedTime',
    label: 'Received Time',
    type: 'AWSDateTime'
  },
  {
    field: 'rssi',
    label: 'rss1',
    type: 'string'
  },
  {
    field: 'stateId',
    label: 'State ID',
    type: 'id'
  },
  {
    field: 'status',
    label: 'Status',
    type: 'string'
  },
  {
    field: 'statusCode',
    label: 'Status Code',
    type: 'string'
  },
  {
    field: 'storageVersion',
    label: 'Storage Version',
    type: 'string'
  },
  {
    field: 'thingName',
    label: 'Thing Name',
    type: 'string'
  },
  {
    field: 'totalFlashes',
    label: 'Total Flashes',
    type: 'string'
  },
  {
    field: 'traceId',
    label: 'Trace ID',
    type: 'string'
  }
];

// SESSIONS

export const oralbSessionsColumns = [
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id'
  },
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Battery level',
    field: 'batteryLevel',
    type: 'number'
  },
  {
    label: 'Client',
    field: 'client',
    type: 'string'
  },
  {
    label: 'Client Version',
    field: 'clientVersion',
    type: 'string'
  },
  {
    label: 'Operating System',
    field: 'operatingSystem',
    type: 'string'
  },
  {
    label: 'Operating System Version',
    field: 'operatingSystemVersion',
    type: 'string'
  },
  {
    label: 'parentDeviceId',
    field: 'parentDeviceId',
    type: 'id'
  },
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Brush score',
    field: 'brushScore',
    type: 'number'
  },
  {
    label: 'Brushing duration',
    field: 'brushingDuration',
    type: 'number'
  },
  {
    label: 'Clean tongue',
    field: 'cleanTongue',
    type: 'string'
  },
  {
    label: 'Coverage percentage',
    field: 'coveragePercentage',
    type: 'number'
  },
  {
    label: 'Floss teeth',
    field: 'flossTeeth',
    type: 'string'
  },
  {
    label: 'Gum bleeding',
    field: 'gumBleeding',
    type: 'string'
  },
  {
    label: 'Handle session id',
    field: 'handleSessionId',
    type: 'number'
  },
  {
    label: 'Gateway Model',
    field: 'gatewayModel',
    type: 'number'
  },
  {
    label: 'Brush Model',
    field: 'brushModel',
    type: 'number'
  },
  {
    label: 'On event count',
    field: 'onEventCount',
    type: 'number'
  },
  {
    label: 'Pressure distribution',
    field: 'pressureDistribution',
    type: 'pressureDistribution'
  },
  {
    label: 'Pressure duration',
    field: 'pressureDuration',
    type: 'number'
  },
  {
    label: 'Pressure event count',
    field: 'pressureEventCount',
    type: 'number'
  },
  {
    label: 'Primary brushing mode',
    field: 'primaryBrushingMode',
    type: 'string'
  },
  {
    label: 'Rinse mouth',
    field: 'rinseMouth',
    type: 'string'
  },
  {
    label: 'Session id',
    field: 'sessionId',
    type: 'id'
  },
  {
    label: 'Session start time',
    field: 'sessionStartTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Session type',
    field: 'sessionType',
    type: 'string'
  },
  {
    label: 'Zoned Brush Time',
    field: 'zonedBrushTime',
    type: 'AWSJSON',
    width: '200px'
  },
  {
    label: 'Zoned Pressure Time',
    field: 'zonedPressureTime',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const opteSessionsColumns = [
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'id'
  },
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'string'
  },
  {
    label: 'Ab Timeout Grace',
    field: 'abTimeoutGrace',
    type: 'string'
  },
  {
    label: 'Ab Timeout Idle',
    field: 'abTimeoutIdle',
    type: 'string'
  },
  {
    label: 'Ab Timeout Silence',
    field: 'abTimeoutSilence',
    type: 'string'
  },
  {
    label: 'Ab Timeout Shutdown',
    field: 'abTimeoutShutdown',
    type: 'string'
  },
  {
    label: 'Abandon Level 0',
    field: 'abandonLevel0',
    type: 'string'
  },
  {
    label: 'Abandon Level 1',
    field: 'abandonLevel1',
    type: 'string'
  },
  {
    label: 'Abandon Level 2',
    field: 'abandonLevel2',
    type: 'string'
  },
  {
    label: 'Abandon Level 3',
    field: 'abandonLevel3',
    type: 'string'
  },
  {
    label: 'Analog Gain',
    field: 'analogGain',
    type: 'string'
  },
  {
    label: 'App Offset X',
    field: 'appOffsetX',
    type: 'string'
  },
  {
    label: 'App Offset Y',
    field: 'appOffsetY',
    type: 'string'
  },
  {
    label: 'Auto Et Enable',
    field: 'autoEtEnable',
    type: 'string'
  },
  {
    label: 'Base Bootcount',
    field: 'baseBootcount',
    type: 'string'
  },
  // {
  //   label: "Base Firmware Build Date",
  //   field: "baseFirmwareBuildDate",
  //   type: "string"
  // },
  {
    label: 'Base Firmware Build Options',
    field: 'baseFirmwareBuildOptions',
    type: 'string'
  },
  {
    label: 'Base Firmware Flag',
    field: 'baseFirmwareFlag',
    type: 'string'
  },
  {
    label: 'Base Firmware Version',
    field: 'baseFirmwareVersion',
    type: 'string'
  },
  {
    label: 'Base Hardware Model',
    field: 'baseHardwareModel',
    type: 'string'
  },
  {
    label: 'Base Hardware Version',
    field: 'baseHardwareVersion',
    type: 'string'
  },
  {
    label: 'Base ID',
    field: 'baseId',
    type: 'id'
  },
  {
    label: 'Base Uptime',
    field: 'baseUptime',
    type: 'string'
  },
  {
    label: 'Blue Led Level',
    field: 'blueLedLevel',
    type: 'string'
  },
  {
    label: 'Camera Etcal',
    field: 'cameraEtcal',
    type: 'string'
  },
  {
    label: 'Camera Lcal',
    field: 'cameraLcal',
    type: 'string'
  },
  {
    label: 'Camera Offset Horz',
    field: 'cameraOffsetHorz',
    type: 'string'
  },
  {
    label: 'Camera Offset Vert',
    field: 'cameraOffsetVert',
    type: 'string'
  },
  {
    label: 'Camera On',
    field: 'cameraOn',
    type: 'string'
  },
  {
    label: 'Camera Performance',
    field: 'cameraPerformance',
    type: 'string'
  },
  {
    label: 'Camera Rotation',
    field: 'cameraRotation',
    type: 'string'
  },
  {
    label: 'Camera Rotational Angle',
    field: 'cameraRotationalAngle',
    type: 'string'
  },
  {
    label: 'Camera X',
    field: 'cameraX',
    type: 'string'
  },
  {
    label: 'Camera Y',
    field: 'cameraY',
    type: 'string'
  },
  {
    label: 'Cart Absent',
    field: 'cartAbsent',
    type: 'string'
  },
  {
    label: 'Cart Cal Alignment Tsr',
    field: 'cartCalAlignmentTsr',
    type: 'string'
  },
  {
    label: 'Cart Cal Alignment Xoff',
    field: 'cartCalAlignmentXoff',
    type: 'string'
  },
  {
    label: 'Cart Cal Alignment Yoff',
    field: 'cartCalAlignmentYoff',
    type: 'string'
  },
  {
    label: 'Cart Change',
    field: 'cartChange',
    type: 'string'
  },
  {
    label: 'Cart Drops Kms In',
    field: 'cartDropsKmsIn',
    type: 'string'
  },
  {
    label: 'Cart Drops Kms Out',
    field: 'cartDropsKmsOut',
    type: 'string'
  },
  {
    label: 'Cart Drops Print',
    field: 'cartDropsPrint',
    type: 'string'
  },
  {
    label: 'Cart Drops Purge',
    field: 'cartDropsPurge',
    type: 'string'
  },
  {
    label: 'Cart ID',
    field: 'cartId',
    type: 'id'
  },
  {
    label: 'Cart Tally',
    field: 'cartTally',
    type: 'string'
  },
  {
    label: 'Cart Time On Skin',
    field: 'cartTimeOnSkin',
    type: 'string'
  },
  {
    label: 'Cleaning',
    field: 'cleaning',
    type: 'string'
  },
  {
    label: 'Consumer ID',
    field: 'consumerId',
    type: 'id'
  },
  {
    label: 'Cradle Events',
    field: 'cradleEvents',
    type: 'string'
  },
  {
    label: 'Cradle In',
    field: 'cradleIn',
    type: 'string'
  },
  {
    label: 'Cradle Out',
    field: 'cradleOut',
    type: 'string'
  },
  {
    label: 'Display Inversion State',
    field: 'displayInversionState',
    type: 'string'
  },
  {
    label: 'Drops Per Click',
    field: 'dropsPerClick',
    type: 'string'
  },
  {
    label: 'Heater Timeout',
    field: 'heaterTimeout',
    type: 'string'
  },
  {
    label: 'Idle Timeout',
    field: 'idleTimeout',
    type: 'string'
  },
  {
    label: 'Image Height',
    field: 'imageHeight',
    type: 'string'
  },
  {
    label: 'Image Width',
    field: 'imageWidth',
    type: 'string'
  },
  {
    label: 'Intensity',
    field: 'intensity',
    type: 'string'
  },
  {
    label: 'Kws Freq Off Skin',
    field: 'kwsFreqOffSkin',
    type: 'string'
  },
  {
    label: 'Kws Freq On Skin',
    field: 'kwsFreqOnSkin',
    type: 'string'
  },
  {
    label: 'Lid Close',
    field: 'lidClose',
    type: 'string'
  },
  {
    label: 'Lid Events',
    field: 'lidEvents',
    type: 'string'
  },
  {
    label: 'Lid Open',
    field: 'lidOpen',
    type: 'string'
  },
  {
    label: 'Liftoff',
    field: 'liftoff',
    type: 'string'
  },
  {
    label: 'Max Drops',
    field: 'maxDrops',
    type: 'string'
  },
  {
    label: 'Min Drops',
    field: 'minDrops',
    type: 'string'
  },
  {
    label: 'Nozzle Prefire Delta',
    field: 'nozzlePrefireDelta',
    type: 'string'
  },
  {
    label: 'Nozzle Prefire Width',
    field: 'nozzlePrefireWidth',
    type: 'string'
  },
  {
    label: 'Nozzle Pulse Delta',
    field: 'nozzlePulseDelta',
    type: 'string'
  },
  {
    label: 'Puck Absent',
    field: 'puckAbsent',
    type: 'string'
  },
  {
    label: 'Puck Events',
    field: 'puckEvents',
    type: 'string'
  },
  {
    label: 'Puck Present',
    field: 'puckPresent',
    type: 'string'
  },
  {
    label: 'Reg Voltage Max',
    field: 'regVoltageMax',
    type: 'string'
  },
  {
    label: 'Reg Voltage Min',
    field: 'regVoltageMin',
    type: 'string'
  },
  {
    label: 'Roi Height',
    field: 'roiHeight',
    type: 'string'
  },
  {
    label: 'Roi Total Width',
    field: 'roiTotalWidth',
    type: 'string'
  },
  {
    label: 'Roller Change',
    field: 'rollerChange',
    type: 'string'
  },
  {
    label: 'Roller Window Height',
    field: 'rollerWindowHeight',
    type: 'string'
  },
  {
    label: 'Roller Window Offset X',
    field: 'rollerWindowOffsetX',
    type: 'string'
  },
  {
    label: 'Roller Window Offset Y',
    field: 'rollerWindowOffsetY',
    type: 'string'
  },
  {
    label: 'Roller Window Width',
    field: 'rollerWindowWidth',
    type: 'string'
  },
  {
    label: 'Ror Height',
    field: 'rorHeight',
    type: 'string'
  },
  {
    label: 'rssi',
    field: 'rssi',
    type: 'number'
  },
  {
    label: 'Session Duration',
    field: 'sessionDuration',
    type: 'string'
  },
  {
    label: 'Session ID',
    field: 'sessionId',
    type: 'id'
  },
  {
    label: 'Session Start Time',
    field: 'sessionStartTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Shade',
    field: 'shade',
    type: 'string'
  },
  {
    label: 'Skin Image Height',
    field: 'skinImageHeight',
    type: 'string'
  },
  {
    label: 'Skin Row Start',
    field: 'skinRowStart',
    type: 'string'
  },
  {
    label: 'Temp Cutoff High',
    field: 'tempCutoffHigh',
    type: 'string'
  },
  {
    label: 'Temp Cutoff Low',
    field: 'tempCutoffLow',
    type: 'string'
  },
  {
    label: 'Temp Shutdown High',
    field: 'tempShutdownHigh',
    type: 'string'
  },
  {
    label: 'Temp Shutdown Low',
    field: 'tempShutdownLow',
    type: 'string'
  },
  {
    label: 'Thermal Event',
    field: 'thermalEvent',
    type: 'string'
  },
  {
    label: 'Threshold',
    field: 'threshold',
    type: 'string'
  },
  {
    label: 'Tip Absent',
    field: 'tipAbsent',
    type: 'string'
  },
  {
    label: 'Tip Events',
    field: 'tipEvents',
    type: 'string'
  },
  {
    label: 'Tip Present',
    field: 'tipPresent',
    type: 'string'
  },
  {
    label: 'Wand Absent',
    field: 'wandAbsent',
    type: 'string'
  },
  {
    label: 'Wand Bootcount',
    field: 'wandBootcount',
    type: 'string'
  },
  {
    label: 'Wand Events',
    field: 'wandEvents',
    type: 'string'
  },
  {
    label: 'Wand Firmware Build Date',
    field: 'wandFirmwareBuildDate',
    type: 'AWSDateTime'
  },
  {
    label: 'Wand Firmware Build Options',
    field: 'wandFirmwareBuildOptions',
    type: 'string'
  },
  {
    label: 'Wand Firmware Flag',
    field: 'wandFirmwareFlag',
    type: 'string'
  },
  {
    label: 'Wand Firmware Version',
    field: 'wandFirmwareVersion',
    type: 'string'
  },
  {
    label: 'Wand Hardware Model',
    field: 'wandHardwareModel',
    type: 'string'
  },
  {
    label: 'Wand Hardware Version',
    field: 'wandHardwareVersion',
    type: 'string'
  },
  {
    label: 'Wand Present',
    field: 'wandPresent',
    type: 'string'
  },
  {
    label: 'Wand Storage',
    field: 'wandStorage',
    type: 'string'
  },
  {
    label: 'Wand Uptime',
    field: 'wandUptime',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const gilletteSessionsColumns = [
  {
    field: 'deviceId',
    label: 'Device ID',
    type: 'id'
  },
  {
    field: 'cartridgeUse',
    label: 'Cartridge Use',
    type: 'string'
  },
  {
    field: 'consumerId',
    label: 'Consumer ID',
    type: 'id'
  },
  {
    field: 'deviceType',
    label: 'Device Type',
    type: 'string'
  },
  {
    field: 'errorCode',
    label: 'Error Code',
    type: 'string'
  },
  {
    field: 'errorMessage',
    label: 'Error Message',
    type: 'string'
  },
  {
    field: 'handleMode',
    label: 'Handle Mode',
    type: 'string'
  },
  {
    field: 'modelVersion',
    label: 'Model Version',
    type: 'string'
  },
  {
    field: 'percentageOverThreshold',
    label: 'Percentage Over Threshold',
    type: 'string'
  },
  {
    field: 'receivedTime',
    label: 'Received Time',
    type: 'string'
  },
  {
    field: 'sessionDuration',
    label: 'Session Duration',
    type: 'string'
  },
  {
    field: 'sessionId',
    label: 'Session ID',
    type: 'id'
  },
  {
    field: 'sessionStartTime',
    label: 'Session Start Time',
    type: 'AWSDateTime'
  },
  {
    field: 'sessionStrokeCount',
    label: 'Session Stroke Count',
    type: 'string'
  },
  {
    field: 'sessionStrokes',
    label: 'Session Strokes',
    type: 'string'
  },
  {
    field: 'sessionStrokeCount',
    label: 'Session Strokes Count',
    type: 'string'
  },
  {
    field: 'thingName',
    label: 'Thing Name',
    type: 'string'
  },
  {
    field: 'traceId',
    label: 'Trace ID',
    type: 'id'
  },
  {
    field: 'threshold1',
    label: 'Threshold1',
    type: 'string'
  },
  {
    field: 'threshold2',
    label: 'Threshold2',
    type: 'string'
  },
  {
    field: 'zonedPressureTime',
    label: 'Zoned Pressure Time',
    type: 'string'
  },
  {
    field: 'zonedRepeatTime',
    label: 'Zoned Repeat Time',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const labsSessionsColumns = [
  {
    label: 'Session ID',
    field: 'sessionId',
    type: 'string'
  },
  {
    label: 'Session Start Time',
    field: 'sessionStartTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Session Type',
    field: 'sessionType',
    type: 'string'
  },
  {
    label: 'Received Time',
    field: 'receivedTime',
    type: 'AWSDateTime'
  },
  {
    label: 'Consumer ID',
    field: 'consumerId',
    type: 'id'
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    type: 'string'
  },
  {
    label: 'Device Type',
    field: 'deviceType',
    type: 'string'
  },
  {
    label: 'Thing Name',
    field: 'thingName',
    type: 'string'
  },
  {
    label: 'Message',
    field: 'message',
    type: 'AWSJSON',
    width: '350px'
  },
  {
    label: 'Operating System',
    field: 'operatingSystem',
    type: 'string'
  },
  {
    label: 'Operating System Version',
    field: 'operatingSystemVersion',
    type: 'string'
  },
  {
    label: 'Client',
    field: 'client',
    type: 'string'
  },
  {
    label: 'Client Version',
    field: 'clientVersion',
    type: 'string'
  },
  {
    label: 'Trace ID',
    field: 'traceId',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const braunSessionsColumns = [
  {
    field: 'client',
    label: 'Client',
    type: 'string'
  },
  {
    field: 'clientVersion',
    label: 'Client Version',
    type: 'string'
  },
  {
    field: 'consumerId',
    label: 'Consumer ID',
    type: 'id'
  },
  {
    field: 'containedIn',
    label: 'Contained In',
    type: 'id'
  },
  {
    field: 'coveragePercentage',
    label: 'Coverage Percentage',
    type: 'string'
  },
  {
    field: 'deviceId',
    label: 'Device ID',
    type: 'string'
  },
  {
    field: 'deviceType',
    label: 'Device Type',
    type: 'string'
  },
  {
    field: 'flashes',
    label: 'Flashes',
    type: 'string'
  },
  {
    field: 'hairColor',
    label: 'Hair Color',
    type: 'string'
  },
  {
    field: 'hairDensity',
    label: 'Hair Density',
    type: 'string'
  },
  {
    field: 'mountedHead',
    label: 'Mounted Head ',
    type: 'string'
  },
  {
    field: 'operatingSystem',
    label: 'Operating System',
    type: 'string'
  },
  {
    field: 'operatingSystemVersion',
    label: 'Operating System Version',
    type: 'string'
  },
  {
    field: 'planId',
    label: 'Plan Id',
    type: 'string'
  },
  {
    field: 'receivedTime',
    label: 'Received Time',
    type: 'string'
  },
  {
    field: 'sensorSettings',
    label: 'Sensor Settings',
    type: 'string'
  },
  {
    field: 'sessionDuration',
    label: 'Session Duration',
    type: 'string'
  },
  {
    field: 'sessionId',
    label: 'Session ID',
    type: 'string'
  },
  {
    field: 'sessionStartTime',
    label: 'Session Start Time',
    type: 'string'
  },
  {
    field: 'sessionStatus',
    label: 'Session Status',
    type: 'string'
  },
  {
    field: 'sessionType',
    label: 'Session Type',
    type: 'string'
  },
  {
    field: 'skinTone',
    label: 'Skin Tone',
    type: 'string'
  },
  {
    field: 'thingName',
    label: 'Thing Name',
    type: 'string'
  },
  {
    field: 'traceId',
    label: 'Trace ID',
    type: 'string'
  },
  {
    field: 'treatmentModeSelected',
    label: 'Treatment Mode Selected',
    type: 'string'
  },
  {
    field: 'treatment',
    label: 'Treatment',
    type: 'string'
  },
  {
    field: 'treatmentPhase',
    label: 'Treatment Phase',
    type: 'string'
  },
  {
    field: 'treatmentPowerAvg',
    label: 'Treatment Power Avg',
    type: 'string'
  },
  {
    field: 'treatmentPowerMax',
    label: 'Treatment Power Max',
    type: 'string'
  },
  {
    field: 'treatmentPowerMin',
    label: 'Treatment Power Min',
    type: 'string'
  },
  {
    field: 'userEvaluation',
    label: 'User Evaluation',
    type: 'string'
  },
  {
    label: 'Delete',
    field: 'delete',
    type: 'string'
  }
];

export const consumablesColumns = [
  {
    field: 'consumableId',
    label: 'Consumable ID',
    type: 'string'
  },
  {
    field: 'deviceId',
    label: 'Device ID',
    type: 'string'
  },
  {
    field: 'consumableType',
    label: 'Consumable Type',
    type: 'string'
  },
  {
    field: 'totalSessionCount',
    label: 'Total Session Count',
    type: 'string'
  },
  {
    field: 'totalSessionDurationSeconds',
    label: 'Total Session Duration Seconds',
    type: 'string'
  },
  {
    field: 'drift',
    label: 'Drift',
    type: 'number'
  },
  {
    field: 'startDate',
    label: 'Start Date',
    type: 'AWSDateTime'
  },
  {
    field: 'state',
    label: 'State',
    type: 'string'
  },
  {
    field: 'edit',
    label: 'Edit',
    type: 'string'
  }
];
