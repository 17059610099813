import getSchemaData from './mockOralBResults/schemaData';
import { getDevicesData } from './mockOralBResults/devicesData';
import { getDeviceData, createDeviceData, updateDeviceData, deleteDeviceData } from './mockOralBResults/deviceData';
import { getJobsData, createJobsData } from './mockOralBResults/jobsData';
import { getSessionsData, deleteSessionData, createSessionData } from './mockOralBResults/sessionsData';
import { getConsumablesData, createConsumablesData } from './mockOralBResults/consumablesData';
import getLogsData from './mockOralBResults/logsData';
import { updateUserData, deleteUserData } from './mockOralBResults/userData';
import { getAllDeviceTypesData, getDeviceTypesData, getDeviceStatusesData, getOralBVoiceConfigsData, getOralBBrushingModeTypesData, getTaskTypesData, getConsumablesTypesData } from './mockOralBResults/enumData';

export default {
  async getSchema (queryObj) {
    return getSchemaData;
  },
  async getDevices (context) {
    return getDevicesData;
  },
  async getDevice (context, queryObj) {
    return getDeviceData;
  },
  async createDevice (context, queryObj) {
    return createDeviceData;
  },
  async updateDevice (context, queryObj) {
    return updateDeviceData;
  },
  async deleteDevice (context, queryObj) {
    return deleteDeviceData;
  },
  async createJob (context, queryObj) {
    return createJobsData;
  },
  async getJobs (context, queryObj) {
    return getJobsData;
  },
  async getSessions (context) {
    return getSessionsData;
  },
  async createSession (context, queryObj) {
    return createSessionData;
  },
  async deleteSession (context, queryObj) {
    return deleteSessionData;
  },
  async getConsumables (context) {
    return getConsumablesData;
  },
  async createConsumable (context, queryObj) {
    return createConsumablesData;
  },
  async getLogs (context) {
    return getLogsData;
  },
  async updateUser (context, queryObj) {
    return updateUserData;
  },
  async deleteUser (context) {
    return deleteUserData;
  },
  async getAllDeviceTypes (context) {
    return getAllDeviceTypesData;
  },
  async getDeviceTypes (context) {
    return getDeviceTypesData;
  },
  async getConsumablesTypes (context) {
    return getConsumablesTypesData;
  },
  async getDeviceStatuses (context) {
    return getDeviceStatusesData;
  },
  async getOralBVoiceConfigs (context) {
    return getOralBVoiceConfigsData;
  },
  async getOralBBrushingModeTypes (context) {
    return getOralBBrushingModeTypesData;
  },
  async getTaskTypes (context) {
    return getTaskTypesData;
  }
};
