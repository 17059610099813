export const getDeviceData = {
  data: {
    device: {
      deviceId: 'abc123',
      deviceType: 'BOOMBOX_ALEXA',
      firmwareVersion: '1.1.1',
      name: 'Test Toothbrush',
      ringColor: '#00FFFF',
      rssi: -65,
      status: 'PROVISIONED'
    }
  }
};

export const createDeviceData = {
  data: {
    createDevice: {
      cert: '',
      privateKey: '',
      device: {
        deviceId: 'efg456',
        deviceType: 'D21_1_MODE'
      }
    }
  }
};

export const updateDeviceData = {
  data: {
    updateDevice: {
      createdBy: 'GQL',
      receivedTime: '2020-11-11T00:46:38+00:00',
      stateId: 'b1e5d654-3f34-4691-a4f4-e5b15c138c90',
      thingName: 'D21_1_MODE_efg456',
      traceId: '',
      brushHeadDate: '2020-11-11T00:46:28+00:00',
      firmwareVersion: null,
      logLevel: null,
      macAddress: null,
      name: 'updating device',
      operatingSystem: null,
      operatingSystemVersion: null,
      parentDeviceId: null,
      protocolVersion: 0,
      remainingBrushHeadDays: 0,
      ringColor: '#FFFFFF',
      rssi: 0,
      ssid: null,
      voiceAssistantConfigured: 'DISABLED'
    }
  }
};

export const deleteDeviceData = {
  data: {
    deleteDevice: 'abc123 Deleted Successfully'
  }
};
