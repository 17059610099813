<template>
  <div>
    <Header />
    <router-view
      :class="`main-view bg-theme-lightest ${signedIn ? 'pl-12' : 'px-24'}`"
    ></router-view>
    <SideNav v-if="signedIn" />
    <div v-if="signedIn" id="signout" class="absolute p-3 top-0 right-0 z-50">
      <amplify-sign-out></amplify-sign-out>
    </div>
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import SideNav from '@/components/UI/SideNav.vue';
import Header from '@/components/UI/Header.vue';

export default {
  components: {
    SideNav,
    Header
  },
  data () {
    return {
      signedIn: null
    };
  },
  methods: {
    async fetchUserAndSetInStore (pushTo) {
      try {
        // session captures CognitoAccessToken and CognitoIdToken tokens
        const session = await Auth.currentSession();
        const userInfo = await Auth.currentAuthenticatedUser();
        this.$store.dispatch('updateUser', { ...session, ...userInfo });
        if (pushTo) this.$router.push(pushTo);
      } catch (e) {
        console.error('No user found', e);
      }
    }
  },
  created () {
    this.fetchUserAndSetInStore(null);
  },
  beforeCreate () {
    Hub.listen('auth', data => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.signedIn = true;
        this.fetchUserAndSetInStore('/');
      }
      if (payload.event === 'signOut') {
        this.$store.dispatch('updateUser', {});
        this.$store.dispatch('resetStore');
        localStorage.clear();
        this.signedIn = false;
        this.$router.push('/auth');
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
        this.fetchUserAndSetInStore();
      })
      .catch(() => {
        this.signedIn = false;
      });
  }
};
</script>

<style lang="scss">
@import "fonts";

* {
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 300;
  color: #777;
  --container-height: 60vh;
}

:root {
  --amplify-font-family: Montserrat;
  --amplify-primary-color: #4285f4;
  --amplify-primary-contrast: #f4f6fa;
  --amplify-primary-tint: #2b6cb0; /* blue-700 */
  --amplify-primary-shade: #3182ce;
  --amplify-secondary-color: #777;
  --amplify-secondary-contrast: #f4f6fa;
  --amplify-text-md: 1rem;
  --amplify-light-grey: #718096;
  --amplify-grey: #777;
  --amplify-white: #f4f6fa;
}

html {
  @apply bg-theme-lightest;
  height: 100%;
}

.input-icon {
  position: absolute;
  left: 0;
  top: 17px;
  color: #cbd5e0;
}

.main-view {
  /* Extract this to variable */
  padding-top: 73px;
  height: 100vh;
  overflow-y: scroll;
}

// adding css to fix prod build for vue good table
.footer__row-count__select {
    width: auto;
    border: 0;
    height: auto;
    margin-left: 8px;
}

.footer__navigation__page-info {
    display: inline;
}

.footer__row-count.vgt-pull-left {
    display: inline;
}

.footer__navigation.vgt-pull-right{
  float: right;
}

input.footer__navigation__page-info__current-entry{
  width: 30px;
  text-align: center;
}

.vgt-wrap__footer {
    color: #606266;
    padding: 1em;
    border: 1px solid #dcdfe6;
    background: linear-gradient(#f4f5f8,#f1f3f6);
    height: 60px;
}

.vgt-responsive {
    overflow-x: scroll;
    width: 100%;
}

input.vgt-input.vgt-pull-left {
    width: 100%;
    height: 32px;
    line-height: 1;
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 12px;
    color: #606266;
    border-radius: 4px;
    box-sizing: border-box;
    background-image: none;
    background-color: #fff;
    border: 1px solid #dcdfe6;
}

.vgt-table.bordered th {
    border: 1px solid #dcdfe6;
}

.vgt-global-search {
    padding: 5px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    border: 1px solid #dcdfe6;
    border-bottom: 0;
    background: linear-gradient(#f4f5f8,#f1f3f6);
}

.vgt-global-search__input.vgt-pull-left {
    width: 100%;
}

.vgt-table th.sortable button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: 0 0;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
