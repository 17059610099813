// ########## OPTE QUERIES ##########

export const UpdateOpteUser = `mutation UpdateOpteUser($input: UpdateUserInput){
  updateUser(input: $input) {
    givenName
    familyName
    timezone
  }
}
`;

// delete user mutation
export const DeleteOpteUser = `mutation deleteUser{
  deleteUser
}
`;

export const GetOpteDeviceTypesQuery = `
query types {
  __type(name: "DeviceType") {
    enumValues {
      name
    }
  }
}`;

export const GetOpteDevicesQuery = `query GetOpteDevicesQuery {
  devices{
    abTimeoutGrace
    abTimeoutIdle
    abTimeoutSilence
    analogGain
    appOffsetX
    appOffsetY
    autoEtEnable
    baseBootcount
    baseFirmwareBuildOptions
    baseFirmwareVersion
    baseHardwareModel
    baseHardwareVersion
    baseUptime
    blueLedLevel
    cameraOffsetHorz
    cameraOffsetVert
    cameraRotation
    cameraRotationalAngle
    cartDropsKmsInLifetime
    cartDropsKmsOutLifetime
    cartDropsPrintLifetime
    cartDropsPurgeLifetime
    cartId
    cartTimeOnSkinLifetime
    deviceId
    deviceType
    displayInversionState
    dropsPerClick
    heaterTimeout
    idleTimeout
    imageHeight
    imageWidth
    intensity
    kwsFreqOffSkin
    maxDrops
    minDrops
    nozzlePrefireDelta
    nozzlePrefireWidth
    nozzlePulseDelta
    nozzlePulseWidth
    ntpServer
    receivedTime
    roiHeight
    roiTotalWidth
    rollerChange
    rollerWindowHeight
    rollerWindowOffsetX
    rollerWindowOffsetY
    rollerWindowWidth
    rorHeight
    rssi
    skinRowStart
    tempCutoffHigh
    tempCutoffLow
    tempShutdownLow
    thingName
    threshold
    wandBootcount
    wandFirmwareBuildOptions
    wandFirmwareVersion
    wandHardwareModel
    wandHardwareVersion
    wandUptime
    wifiFirmwareVersion
  }
}`;

export const ClaimOpteDeviceMutation = `
mutation ClaimOpteDevice(
  $deviceType: DeviceType!,
  $certHash: String!,
  $deviceId: ID!
) {
  claimDevice(
    deviceType: $deviceType,
    certHash: $certHash,
    deviceId: $deviceId
  ) {
    deviceType
  }
}`;

export const GetOpteDeviceQuery = `query GetOpteDeviceQuery($deviceId: String!){
  device(deviceId: $deviceId){
    abTimeoutGrace
    abTimeoutIdle
    abTimeoutSilence
    analogGain
    appOffsetX
    appOffsetY
    autoEtEnable
    baseBootcount
    baseFirmwareBuildOptions
    baseFirmwareVersion
    baseHardwareModel
    baseHardwareVersion
    baseUptime
    blueLedLevel
    cameraOffsetHorz
    cameraOffsetVert
    cameraRotation
    cameraRotationalAngle
    cartDropsKmsInLifetime
    cartDropsKmsOutLifetime
    cartDropsPrintLifetime
    cartDropsPurgeLifetime
    cartId
    cartTimeOnSkinLifetime
    deviceId
    deviceType
    displayInversionState
    dropsPerClick
    heaterTimeout
    idleTimeout
    imageHeight
    imageWidth
    intensity
    kwsFreqOffSkin
    maxDrops
    minDrops
    nozzlePrefireDelta
    nozzlePrefireWidth
    nozzlePulseDelta
    nozzlePulseWidth
    ntpServer
    receivedTime
    roiHeight
    roiTotalWidth
    rollerChange
    rollerWindowHeight
    rollerWindowOffsetX
    rollerWindowOffsetY
    rollerWindowWidth
    rorHeight
    rssi
    skinRowStart
    tempCutoffHigh
    tempCutoffLow
    tempShutdownLow
    thingName
    threshold
    wandBootcount
    wandFirmwareBuildOptions
    wandFirmwareVersion
    wandHardwareModel
    wandHardwareVersion
    wandUptime
    wifiFirmwareVersion
  }
}`;

export const UpdateOpteDeviceMutation = `
  mutation update(
    $device: UpdateDeviceInput!,
  ){
    updateDevice(
      input:$device
    ){
      deviceId
      ntpServer
    }
  }`;

export const CreateOpteSessionMutation = `
  mutation createSession(
    $session: CreateSessionInput!,
  ){
    createSession(
      input: $session
    ){
      deviceId
    }
  }`;

export const DeleteOpteDeviceMutation = `
mutation unclaimDevice(
  $deviceId: ID!
  $deviceType: DeviceType!,
) {
  unclaimDevice(
    deviceId: $deviceId,
    deviceType: $deviceType
  )
}`;

export const GetOpteSessionsQuery = `query GetApplicatorSessions {
  sessions(start: "1970-01-01T00:00:00+00:00", end: "2999-01-01T00:00:00+00:00", limit: 1000) {
    items {
      abTimeoutGrace
      abTimeoutIdle
      abTimeoutShutdown
      abTimeoutSilence
      abandonLevel0
      abandonLevel1
      abandonLevel2
      abandonLevel3
      analogGain
      appOffsetX
      appOffsetY
      autoEtEnable
      baseBootcount
      baseFirmwareBuildDate
      baseFirmwareBuildOptions
      baseFirmwareFlag
      baseFirmwareVersion
      baseHardwareModel
      baseHardwareVersion
      baseId
      baseUptime
      blueLedLevel
      cameraEtcal
      cameraLcal
      cameraOffsetHorz
      cameraOffsetVert
      cameraOn
      cameraPerformance
      cameraRotation
      cameraRotationalAngle
      cameraX
      cameraY
      cartAbsent
      cartCalAlignmentTsr
      cartCalAlignmentXoff
      cartCalAlignmentYoff
      cartChange
      cartDropsKmsIn
      cartDropsKmsOut
      cartDropsPrint
      cartDropsPurge
      cartId
      cartTally
      cartTimeOnSkin
      cleaning
      consumerId
      cradleEvents
      cradleIn
      cradleOut
      deviceId
      deviceType
      displayInversionState
      dropsPerClick
      heaterTimeout
      idleTimeout
      imageHeight
      imageWidth
      intensity
      kwsFreqOffSkin
      kwsFreqOnSkin
      lidClose
      lidEvents
      lidOpen
      liftoff
      maxDrops
      minDrops
      nozzlePrefireDelta
      nozzlePrefireWidth
      nozzlePulseDelta
      nozzlePulseWidth
      puckAbsent
      puckEvents
      puckPresent
      receivedTime
      regVoltageMax
      regVoltageMin
      roiHeight
      roiTotalWidth
      rollerChange
      rollerStop
      rollerWindowHeight
      rollerWindowOffsetX
      rollerWindowOffsetY
      rollerWindowWidth
      rorHeight
      rssi
      sessionDuration
      sessionId
      sessionStartTime
      shade
      skinImageHeight
      skinRowStart
      tempCutoffHigh
      tempCutoffLow
      tempShutdownHigh
      tempShutdownLow
      thermalEvent
      thingName
      threshold
      tipAbsent
      tipEvents
      tipPresent
      traceId
      wandAbsent
      wandBootcount
      wandEvents
      wandFirmwareBuildDate
      wandFirmwareBuildOptions
      wandFirmwareFlag
      wandFirmwareVersion
      wandHardwareModel
      wandHardwareVersion
      wandPresent
      wandStorage
      wandUptime
      wifiFirmwareVersion
    }
  }
}`;

export const DeleteOpteSessionMutation = `
mutation deleteSession($sessionId: String!) {
  deleteSession(sessionId:$sessionId)
}`;
