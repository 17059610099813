import Vue from 'vue';
import Router from 'vue-router';
import { handleAuthRedirects } from '@/router/routerHooks';

Vue.use(Router);

const isAuthDisabled = process.env.VUE_APP_CYPRESS_DISABLE_AUTH === 'TRUE';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    redirect: '/homepage',
    meta: { requiresAuth: !isAuthDisabled },
    children: [
      {
        path: '/devices',
        name: 'devices',
        component: () => import('@/views/Devices.vue')
      },
      {
        path: '/devices/:deviceId',
        name: 'deviceDetail',
        component: () => import('@/views/DeviceDetail.vue')
      },
      {
        path: '/sessions',
        name: 'sessions',
        component: () => import('@/views/Sessions.vue')
      },
      {
        path: '/consumables',
        name: 'consumables',
        component: () => import('@/views/Consumables.vue')
      },
      {
        path: '/integrations',
        name: 'integrations',
        component: () => import('@/views/Integrations.vue')
      },
      {
        path: '/validations',
        name: 'validations',
        component: () => import('@/views/Validations.vue')
      },
      {
        path: '/logs',
        name: 'logs',
        component: () => import('@/views/Logs.vue')
      },
      {
        path: '/advanced',
        name: 'advanced',
        component: () => import('@/views/Advanced.vue')
      },
      {
        path: '/account-info',
        name: 'account-info',
        component: () => import('@/views/AccountInfo.vue')
      },
      {
        path: '/homepage',
        name: 'homepage',
        component: () => import('@/views/Homepage.vue')
      },
      {
        path: '/verify',
        name: 'verify',
        component: () => import('@/views/VerifyEmail.vue')
      }
    ],
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue')
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(handleAuthRedirects);

export default router;
