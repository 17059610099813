import Amplify from 'aws-amplify';
import isLocal from './isItLocal';
import AWSConfigOptions from '@/util/AWSConfigOptions';

export default config => {
  const selectedConfig = config || defaultConfig;

  selectedConfig.cookieStorage = {
    domain: window.location.hostname,
    path: '/',
    secure: !isLocal
  };

  Amplify.configure(selectedConfig);
};

// Unformatted Default Config
const UDC = AWSConfigOptions[0];
const defaultConfig = {
  brandName: UDC.brandName,
  aws_appsync_graphqlEndpoint: UDC.aws_appsync_graphqlEndpoint,
  aws_appsync_region: UDC.aws_appsync_region,
  aws_appsync_authenticationType: UDC.aws_appsync_authenticationType,
  aws_cognito_identity_pool_id: UDC.aws_cognito_identity_pool_id,
  region: UDC.region,
  aws_user_pools_id: UDC.aws_user_pools_id,
  aws_user_pools_web_client_id: UDC.aws_user_pools_web_client_id
};
