const AWSConfigOptions = [
  {
    brandName: process.env.VUE_APP_ORALCARE_BRAND_NAME,
    locale: process.env.VUE_APP_ORALCARE_LOCALE,
    token: process.env.VUE_APP_ORALCARE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_ORALCARE_EU_BRAND_NAME,
    locale: process.env.VUE_APP_ORALCARE_EU_LOCALE,
    token: process.env.VUE_APP_ORALCARE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_ORALCARE_AP_BRAND_NAME,
    locale: process.env.VUE_APP_ORALCARE_AP_LOCALE,
    token: process.env.VUE_APP_ORALCARE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_OPTE_BRAND_NAME,
    locale: process.env.VUE_APP_OPTE_LOCALE,
    token: process.env.VUE_APP_OPTE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_GILLETTE_BRAND_NAME,
    locale: process.env.VUE_APP_GILLETTE_LOCALE,
    token: process.env.VUE_APP_GILLETTE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_GILLETTE_EU_BRAND_NAME,
    locale: process.env.VUE_APP_GILLETTE_EU_LOCALE,
    token: process.env.VUE_APP_GILLETTE_TOKEN
  },
  {
    brandName: process.env.VUE_APP_LABS_BRAND_NAME,
    locale: process.env.VUE_APP_LABS_LOCALE,
    token: process.env.VUE_APP_LABS_TOKEN

  },
  {
    brandName: process.env.VUE_APP_LABS_EU_BRAND_NAME,
    locale: process.env.VUE_APP_LABS_EU_LOCALE,
    token: process.env.VUE_APP_LABS_TOKEN
  },
  {
    brandName: process.env.VUE_APP_LABS_AP_BRAND_NAME,
    locale: process.env.VUE_APP_LABS_AP_LOCALE,
    token: process.env.VUE_APP_LABS_TOKEN
  },
  {
    brandName: process.env.VUE_APP_BRAUN_BRAND_NAME,
    locale: process.env.VUE_APP_BRAUN_LOCALE,
    token: process.env.VUE_APP_BRAUN_TOKEN
  },
  {
    brandName: process.env.VUE_APP_BRAUN_AP_BRAND_NAME,
    locale: process.env.VUE_APP_BRAUN_AP_LOCALE,
    token: process.env.VUE_APP_BRAUN_TOKEN
  },
  {
    brandName: process.env.VUE_APP_BRAUN_EU_BRAND_NAME,
    locale: process.env.VUE_APP_BRAUN_EU_LOCALE,
    token: process.env.VUE_APP_BRAUN_TOKEN
  }
];

export default AWSConfigOptions;
