<template>
  <sidebar-menu
    class="iot-sidebar"
    :menu="menu"
    width="200px"
    :collapsed="true"
    hiddenOnCollapse="true"
  >
    <span slot="dropdown-icon">
      <FontAwesomeIcon icon="chevron-right" />
    </span>
  </sidebar-menu>
</template>

<script>
import currentBrand from '@/util/getCurrentBrand';

export default {
  data () {
    return {
      menu: [
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'home'
            }
          },
          attributes: {
            'data-cy': 'home'
          },
          title: 'Home',
          href: '/homepage'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'tablet-alt'
            }
          },
          attributes: {
            'data-cy': 'devices'
          },
          title: 'Devices',
          href: '/devices'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'clipboard-list'
            }
          },
          attributes: {
            'data-cy': 'sessions'
          },
          title: 'Sessions',
          href: '/sessions'
        },
        {
          hidden: !currentBrand().includes('Oral-B'),
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'cloud'
            }
          },
          attributes: {
            'data-cy': 'consumables'
          },
          title: 'Consumables',
          href: '/consumables'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'list'
            }
          },
          attributes: {
            'data-cy': 'logs'
          },
          title: 'Logs',
          href: '/logs'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'passport'
            }
          },
          attributes: {
            'data-cy': 'advanced'
          },
          title: 'Advanced',
          href: '/advanced'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'address-card'
            }
          },
          attributes: {
            'data-cy': 'user-info'
          },
          title: 'Account Info',
          href: '/account-info'
        },
        {
          icon: {
            element: 'FontAwesomeIcon',
            class: 'p-2',
            attributes: {
              icon: 'sign-out-alt'
            }
          },
          title: 'Logout',
          href: '/logout',
          attributes: {
            'data-cy': 'logout'
          }
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.v-sidebar-menu.iot-sidebar {
  * {
    @apply .text-white;
  }

  &.vsm_collapsed {
    .vsm--toggle-btn {
      display: none;
    }
  }
  &.vsm_expanded {
    .vsm--toggle-btn {
      left: 200px;
    }
  }
  .vsm--toggle-btn {
    position: absolute;
    top: 0;
    width: 70px;
    height: 70px;
    background: transparent;
    transition: left 0.3s ease;
    outline: none;
  }
}
</style>
