export const getDevicesData = {
  data: {
    devices: [
      {
        deviceType: 'SEAGATE',
        deviceId: '123abc',
        macAddress: null,
        ringColor: '#FFFFFF',
        ssid: null,
        name: null,
        status: 'FACTORY',
        protocolVersion: 0,
        firmwareVersion: null,
        brushHeadDate: '2020-11-11T00:16:03+00:00',
        voiceAssistantConfigured: 'DISABLED',
        rssi: 0
      },
      {
        deviceType: 'D21_2A_MODE',
        deviceId: 'abc123',
        macAddress: null,
        ringColor: '#FFFFFF',
        ssid: null,
        name: null,
        status: 'FACTORY',
        protocolVersion: 0,
        firmwareVersion: null,
        brushHeadDate: '2020-11-10T19:40:58+00:00',
        voiceAssistantConfigured: 'DISABLED',
        rssi: 0
      },
      {
        deviceType: 'SEAGATE',
        deviceId: 'testabc123',
        macAddress: null,
        ringColor: '#FFFFFF',
        ssid: null,
        name: null,
        status: 'FACTORY',
        protocolVersion: 0,
        firmwareVersion: null,
        brushHeadDate: '2020-11-10T01:24:32+00:00',
        voiceAssistantConfigured: 'DISABLED',
        rssi: 0
      },
      {
        deviceType: 'BETAMAX',
        deviceId: '8978899',
        macAddress: null,
        ringColor: '#FFFFFF',
        ssid: null,
        name: null,
        status: 'FACTORY',
        protocolVersion: 0,
        firmwareVersion: null,
        brushHeadDate: '2020-11-10T19:21:58+00:00',
        voiceAssistantConfigured: 'DISABLED',
        rssi: 0
      }
    ]
  }
};
