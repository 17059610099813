export const GetBraunDeviceQuery = `query Device($deviceId: String!) {
  device(deviceId: $deviceId) {
      calibration
      consumerId
      createdBy
      deviceId
      deviceType
      features
      firmwareVersion
      firmwareVersionIPL
      hardwareModel
      hardwareVersion
      logLevel
      mountedHead
      operatingSystem
      operatingSystemVersion
      receivedTime
      rssi
      stateId
      status
      statusCode
      storageVersion
      thingName
      totalFlashes
      traceId
    }
  }
`;
export const GetBraunDevicesQuery = `query Devices {
  devices {
    calibration
    consumerId
    createdBy
    deviceId
    deviceType
    features
    firmwareVersion
    firmwareVersionIPL
    hardwareModel
    hardwareVersion
    logLevel
    mountedHead
    operatingSystem
    operatingSystemVersion
    receivedTime
    rssi
    stateId
    status
    statusCode
    storageVersion
    thingName
    totalFlashes
    traceId
    }
  }
`;

export const GetBraunSessionsQuery = `query Sessions{
  sessions(start: "1970-01-01T00:00:00+00:00", end: "2999-01-01T00:00:00+00:00", limit: 1000){
items {
        client
        clientVersion
        consumerId
        containedIn
        coveragePercentage
        deviceId
        deviceType
        flashes
        hairColor
        hairDensity
        mountedHead
        operatingSystem
        operatingSystemVersion
        planId
        receivedTime
        sensorSettings
        sessionDuration
        sessionId
        sessionStartTime
        sessionStatus
        sessionType
        skinTone
        thingName
        traceId
        treatmentModeSelected
        treatment
        treatmentPhase
        treatmentPowerAvg
        treatmentPowerMax
        treatmentPowerMin
        userEvaluation
      }
      nextToken
    }
  }
`;

export const CreateBraunDevice = `mutation CreateDevice($deviceId: ID!, $deviceType: DeviceType!) {
  createDevice(deviceId: $deviceId, deviceType: $deviceType) {
    device {
      deviceId
    }
  }
}
`;

export const CreateBraunSession = `
mutation CreateSession($input: CreateSessionInput!) {
  createSession(input: $input) {
    client
    clientVersion
    consumerId
    containedIn
    coveragePercentage
    deviceId
    deviceType
    flashes
    hairColor
    hairDensity
    mountedHead
    operatingSystem
    operatingSystemVersion
    planId
    receivedTime
    sensorSettings
    sessionDuration
    sessionId
    sessionStartTime
    sessionStatus
    sessionType
    skinTone
    thingName
    traceId
    treatmentModeSelected
    treatment
    treatmentPhase
    treatmentPowerAvg
    treatmentPowerMax
    treatmentPowerMin
    userEvaluation
  }
}
`;
export const DeleteBraunDevice = `
mutation DeleteDeviceMutation(
  $deviceId: ID!,
  $deviceType: DeviceType!
) {
  deleteDevice(
    deviceId: $deviceId,
    deviceType: $deviceType
  )
}`;
export const DeleteBraunSession = `mutation DeleteSession($sessionId: String!) {
  deleteSession(sessionId: $sessionId)
}
`;
export const DeleteBraunUser = `mutation DeleteUser {
  deleteUser
}
`;
export const UpdateBraunDevice = `
  mutation update(
    $device: DeviceInput
  ) {
    updateDevice(input: $device
  ) {
    createdBy
    deviceId
    deviceType
    receivedTime
    stateId
    thingName
    traceId
    consumerId
    status
    calibration
    features
    firmwareVersion
    firmwareVersionIPL
    hardwareModel
    hardwareVersion
    logLevel
    mountedHead
    operatingSystem
    operatingSystemVersion
    rssi
    statusCode
    storageVersion
    totalFlashes
  }
}
`;

// front end does not currently query or mutate user
export const UpdateBraunUser = `mutation UpdateUser($input: UpdateUserInput) {
  updateUser(input: $input) {
    consumerId
      country
      createdAt
      email
      familyName
      givenName
      phoneNumber
      termsAndConditions
      timezone
      userSurvey
  }
}
`;
