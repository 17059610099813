<template>
  <div>
    <label :for="$attrs.name" class="text-xs text-black">{{ label }}</label>
    <div class="relative">
      <FontAwesomeIcon v-if="icon" class="icon" :icon="icon" />
      <input
        class="border-bottom bg-transparent border-gray-400 mb-4 p-3 pl-6 outline-none w-full"
        v-bind="$attrs"
        :value="value"
        @input="updateValue"
        @blur="$emit('blur')"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: String,
    icon: String,
    label: String
  },
  methods: {
    updateValue (event) {
      if (event.target.id === 'deviceType') {
        this.$emit('input', event.target.value.toUpperCase());
      } else {
        this.$emit('input', event.target.value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ring-color {
  input {
    padding-left: 2rem;
  }
}

.border-bottom {
  border-bottom: 1px solid;
}

.icon {
  position: absolute;
  left: 0;
  top: 17px;
  color: #cbd5e0;
}
</style>
